import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { bufferTime, filter, map } from 'rxjs/operators';
import { WidgetOutputUpdate } from '../models/widget-output-update.model';
import * as WidgetActions from '../state/widgets/widgets.actions';

@Injectable({
  providedIn: 'root',
})
export class OutputService {
  private readonly _outputUpdates$ = new Subject<WidgetOutputUpdate>();

  constructor(private readonly store: Store) {
    this._outputUpdates$
      .pipe(
        bufferTime(50),
        filter((actions) => actions.length > 0),
        map((actions) => new WidgetActions.UpdateWidgetOutputs(actions))
      )
      .subscribe((action) => this.store.dispatch(action));
  }

  public updateOutput(update: WidgetOutputUpdate) {
    this._outputUpdates$.next(update);
  }
}
