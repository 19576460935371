import { createNamedExpressionResolver } from './../types';
import type { SwitchExpression } from '@trackback/widgets';
import { isEqual, map } from 'lodash-es';

export const switchResolver = createNamedExpressionResolver<SwitchExpression>(
  'switch',
  (parser, options, onDataChange, [condition, cases, defaultCase]) => {
    return parser.parseArray(
      [condition, map(cases, 'case')] as const,
      {
        error: onDataChange.error,
        next: ([resolvedCondition, resolvedCaseConditions]) => {
          const resolvedCases = cases.map((switchCaseOption, idx) => ({
            ...switchCaseOption,
            case: resolvedCaseConditions[idx],
          }));
          const matchingCase = resolvedCases.find((resolvedSwitchCase) =>
            isEqual(resolvedCondition, resolvedSwitchCase.case)
          );
          if (matchingCase) {
            return parser.parseWithCallback(
              matchingCase.value,
              onDataChange,
              options
            );
          } else if (defaultCase !== undefined) {
            return parser.parseWithCallback(defaultCase, onDataChange, options);
          } else {
            if (options && options.log) {
              options.log(
                `Could not match ${resolvedCondition} to any case and there is no default case defined`
              );
            }
            return onDataChange.next(undefined);
          }
        },
      },
      options
    );
  }
);
