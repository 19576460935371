import {mapValues, pickBy} from 'lodash-es';

export function safeParseJson(json: any) {
  if (typeof json === 'string') {
    try {
      return JSON.parse(json.trim());
    } catch (exception) {
      if (json === '' || json === 'undefined') {
        return undefined;
      }
    }
  }
  return json;
}

export function convertUndefined(expr: any) {
  if (expr === undefined || expr === null) {
    return null;
  } else if (Array.isArray(expr)) {
    return expr.map(v => convertUndefined(v));
  } else if (typeof expr === 'object') {
    return mapValues(pickBy(expr, (value, key) => value !== undefined), value => convertUndefined(value));
  } else {
    return expr;
  }
}
