import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Register } from '@trackback/ng-common';
import { SlideToggleFieldInput, SlideToggleFieldOutput } from '@trackback/widgets';
import { BaseFormFieldWidgetComponent } from '../base-form-field-widget.component';

@Register('SlideToggleField')
@Component({
  selector: 'tb-slide-toggle-field',
  templateUrl: './slide-toggle-field.component.html',
  styleUrls: ['./slide-toggle-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SlideToggleFieldComponent extends BaseFormFieldWidgetComponent<SlideToggleFieldInput, SlideToggleFieldOutput> implements OnInit {

}
