import type { IfExpression } from '@trackback/widgets';
import { createNamedExpressionResolver } from '../types';

export const ifResolver = createNamedExpressionResolver<IfExpression>(
  'if',
  (parser, options, onDataChange, [condition, then, otherwise]) =>
    parser.parseWithCallback(
      condition,
      {
        error: onDataChange.error,
        next: (resolvedCondition) =>
          resolvedCondition
            ? parser.parseWithCallback(then, onDataChange, options)
            : parser.parseWithCallback(otherwise, onDataChange, options),
      },
      options
    )
);
