import {
  APP_CONFIG,
  Register
} from '@trackback/ng-common';
import { SwitchInputModel, WidgetInputModel } from '@trackback/widgets';
import { SwitchCaseModel } from '@trackback/widgets/build/main/widgets/structural-definitions/switch';
import { isEqual } from 'lodash-es';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  WidgetDefinitionTuple,
} from '../models/widget-input.model';
import { ParserService } from '../services/parser.service';
import { STRUCTURAL_WIDGET_TYPE_GROUP } from './index';
import { WidgetResolver } from './widget-resolver';

@Register('switch', STRUCTURAL_WIDGET_TYPE_GROUP)
export class SwitchWidget extends WidgetResolver<SwitchInputModel<any>> {
  private readonly _parser = this._injector.get(ParserService);
  private readonly _config = this._injector.get(APP_CONFIG, null);

  getState(context?: Record<string, any>): Observable<WidgetDefinitionTuple[]> {
    const switchCaseValues$ = combineLatest(
      this._input.cases.map((c) =>
        this._parser.parse(c, {
          keyWhitelist: ['match'],
          context: context,
          log:
            !this._config || !this._config.PRODUCTION ? console.log : undefined,
        })
      )
    );
    const inputValue$ = this._parser.parse(this._input.value, {
      context: context,
      log: !this._config || !this._config.PRODUCTION ? console.log : undefined,
    });
    return combineLatest([inputValue$, switchCaseValues$]).pipe(
      map(([resolvedSwitchInputValue, resolvedSwitchCases]) => {
        for (const resolvedSwitchCase of resolvedSwitchCases) {
          const typedResolvedSwitchCase =
            resolvedSwitchCase as SwitchCaseModel<WidgetInputModel>;
          if (
            isEqual(typedResolvedSwitchCase.match, resolvedSwitchInputValue)
          ) {
            return [
              [
                typedResolvedSwitchCase.widget,
                context,
              ] as WidgetDefinitionTuple,
            ];
          }
        }
        return [];
      })
    );
  }
}
