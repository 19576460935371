import { InjectionToken } from '@angular/core';
import { LocalActionModel, WidgetActionModel } from '@trackback/widgets';
import { Observable } from 'rxjs';

export type LocalActionDispatcher = (action: LocalActionModel) => Observable<any>;
export type ActionDispatcher = (action?: WidgetActionModel | WidgetActionModel[], context?: Record<string, any>) => Observable<any>;
export interface ActionDispatcherService {
  dispatch: ActionDispatcher;
}

export const ACTION_DISPATCHER = new InjectionToken<ActionDispatcherService>('@trackback/ng-widgets.global-action-dispatcher');
