import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Injectable } from "@angular/core";
import {
  Action,
  NgxsOnInit,
  Selector,
  State,
  StateContext,
  createSelector,
} from "@ngxs/store";
import { LayoutOrientation } from "../../models/layout/layout-orientation.model";
import { LayoutSize } from "../../models/layout/layout-size.model";
import * as Actions from "./layout.actions";

export interface LayoutStateModel {
  activeClientId: number | null;
  orientation?: LayoutOrientation;
  size?: LayoutSize;
}

export const InitialLayoutStateModel: LayoutStateModel = {
  activeClientId: null,
  orientation: undefined,
  size: undefined,
};

@State<LayoutStateModel>({
  name: "layout",
  defaults: InitialLayoutStateModel,
})
@Injectable()
export class LayoutState implements NgxsOnInit {
  @Selector()
  static getActiveClientId(state: LayoutStateModel) {
    return state.activeClientId;
  }

  @Selector()
  static getOrientation(state: LayoutStateModel) {
    return state.orientation;
  }

  @Selector()
  static getSize(state: LayoutStateModel) {
    return state.size;
  }

  @Selector([LayoutState.getSize])
  static isSizeFn(_, size: LayoutSize) {
    const fn = (sizes: LayoutSize | LayoutSize[]) => {
      if (Array.isArray(sizes)) {
        return sizes.includes(size);
      } else {
        return sizes === size;
      }
    };
    return fn;
  }

  static isSize(size: LayoutSize) {
    const fn = createSelector([LayoutState.getSize], (actualSize) => {
      return size === actualSize;
    });
    return fn;
  }

  @Action(Actions.SetActiveClientId)
  setActiveClientId(
    { patchState }: StateContext<LayoutStateModel>,
    { clientId }: Actions.SetActiveClientId
  ) {
    return patchState({
      activeClientId: clientId,
    });
  }

  @Action(Actions.SetOrientation)
  setOrientation(
    { patchState }: StateContext<LayoutStateModel>,
    { orientation }: Actions.SetOrientation
  ) {
    return patchState({
      orientation,
    });
  }

  @Action(Actions.SetSize)
  setSize(
    { patchState }: StateContext<LayoutStateModel>,
    { size }: Actions.SetSize
  ) {
    return patchState({
      size,
    });
  }

  constructor(private breakpointObserver: BreakpointObserver) {}

  observeBreakpoint(mediaQueries: string[], operator: "or" | "and" = "or") {
    return this.breakpointObserver.observe(
      operator === "and" ? mediaQueries.join(" allTruthyIn ") : mediaQueries
    );
  }

  ngxsOnInit({ dispatch }: StateContext<any>): void | any {
    this.observeBreakpoint([Breakpoints.XSmall]).subscribe((state) => {
      if (state.matches) {
        dispatch(new Actions.SetSize("xsmall"));
      }
    });

    this.observeBreakpoint([Breakpoints.Small]).subscribe((state) => {
      if (state.matches) {
        dispatch(new Actions.SetSize("small"));
      }
    });

    this.observeBreakpoint([Breakpoints.Medium]).subscribe((state) => {
      if (state.matches) {
        dispatch(new Actions.SetSize("medium"));
      }
    });

    this.observeBreakpoint([Breakpoints.Large, Breakpoints.XLarge]).subscribe(
      (state) => {
        if (state.matches) {
          dispatch(new Actions.SetSize("large"));
        }
      }
    );

    this.observeBreakpoint(["(orientation: landscape)"]).subscribe((state) => {
      if (state.matches) {
        dispatch(new Actions.SetOrientation("landscape"));
      }
    });

    this.observeBreakpoint(["(orientation: portrait)"]).subscribe((state) => {
      if (state.matches) {
        dispatch(new Actions.SetOrientation("portrait"));
      }
    });
  }
}
