import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Register } from '@trackback/ng-common';
import {
  BaseFormFieldWidgetComponent,
  NgWidgetsModule,
} from '@trackback/ng-widgets';
// import {BindingExpression} from '../../models/widget-input.model';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CommonModule } from '@angular/common';
import {
  MatLegacyChipInputEvent as MatChipInputEvent,
  MatLegacyChipsModule as MatChipsModule,
} from '@angular/material/legacy-chips';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { ChipsFieldInput, ChipsFieldOutput } from '@trackback/widgets';
import { Chip } from '@trackback/widgets/build/main/widgets/definitions/chips-field';
import { clone } from 'lodash-es';

@Register('ChipsField')
@Component({
  selector: 'tb-chips-field',
  templateUrl: './chips-field.component.html',
  styleUrls: ['./chips-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule, NgWidgetsModule, MatFormFieldModule, MatChipsModule],
})
export class ChipsFieldComponent
  extends BaseFormFieldWidgetComponent<ChipsFieldInput, ChipsFieldOutput>
  implements OnInit
{
  // TODO: add explicit constructor

  verticalOrientation = false;
  removable = false;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  chipsInput: Chip[] = [];
  validationRegex;

  @ViewChild('chipList') chipList;

  removePreviousValue() {
    this.updateOutput({ value: [] });
  }

  add(event: MatChipInputEvent): void {
    const { input, value } = event;

    let valid = value != null;

    if (this.validationRegex != null) {
      const validationMatch = value.match(this.validationRegex);
      valid = validationMatch != null && validationMatch.length > 0;
    }

    const labelValue = (value || '').trim();

    if (labelValue !== '') {
      this.chipsInput.push({ label: labelValue.trim(), valid });

      console.log(
        this.chipsInput,
        this.chipsInput.map(e => e.label)
      );

      this._value = this.chipsInput.map(item => item['label']);
      this.chipList.errorState = !valid;
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(chip: Chip): void {
    const index = this.chipsInput.indexOf(chip);
    if (index >= 0) {
      if (chip.valid === false) {
        this.chipList.errorState = false;
      }
      this.chipsInput.splice(index, 1);
      this._value = this.chipsInput.map(item => item['label']);
    }
  }

  async ngOnInit() {
    await super.ngOnInit();
    this.chipList.errorState = false;
    this.chipsInput = clone(this._formControl.value) || [];
    const chipsList: any = this.chipsInput.map(item => {
      return { label: item };
    });
    this.chipsInput = chipsList;
    this.validationRegex = this.input.validationRegex;
    this.removable = this.input.removable ? this.input.removable : true;
    if (this.input.orientation === 'vertical') {
      this.verticalOrientation = true;
    }
  }
}
