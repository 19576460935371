<table mat-table [dataSource]="dataSource"
  [style.paddingLeft]="paddingLeft" [style.paddingRight]="paddingRight"
       [style.paddingTop]="paddingTop" [style.paddingBottom]="paddingBottom">

  <ng-container *ngFor="let columnDef of input.columns" [matColumnDef]="columnDef.name | parse:context | async" [sticky]="columnDef.sticky">
    <ng-container *ngFor="let cellDef of columnDef.cells">
      <ng-container [ngSwitch]="cellDef.type">
        <ng-container *ngSwitchCase="'header'">
          <th mat-header-cell *matHeaderCellDef>
            <ng-container *ngTemplateOutlet="expressionOrWidget; context: { widget: cellDef.widget, context: context }"></ng-container>
          </th>
        </ng-container>
        <ng-container *ngSwitchCase="'data'">
          <td mat-cell *matCellDef="let row">
            <ng-container *ngIf="mergeContexts(context, wrapContext(row, input?.rowDataAlias || 'row')) as mergedContext">
              <ng-container *ngTemplateOutlet="expressionOrWidget; context: { widget: (cellDef.widget | parse:mergedContext:{maxParseDepth: 1, keyWhitelist: ['id']}) | async, context: mergedContext }"></ng-container>
            </ng-container>
          </td>
        </ng-container>
        <ng-container *ngSwitchCase="'footer'">
          <td mat-footer-cell *matFooterCellDef>
            <ng-container [dynamicWidget]="cellDef.widget" [context]="context"></ng-container>
          </td>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>

<!-- The following code throws an error if used as the only source of row definitions, at least one header and data row
 has to be defined in a hardcoded manner (see below)-->
  <tr mat-header-row *matHeaderRowDef="input.mainHeaderRow.activeColumns | parse:context | async; sticky: input.mainHeaderRow.sticky"></tr>
  <ng-container *ngFor="let rowDef of input.rows">
    <ng-container [ngSwitch]="rowDef.type">
      <ng-container *ngSwitchCase="'header'">
        <tr mat-header-row *matHeaderRowDef="rowDef.activeColumns | parse:context | async; sticky: rowDef.sticky"></tr>
      </ng-container>
      <ng-container *ngSwitchCase="'data'">
        <tr mat-row *matRowDef="let row; columns: rowDef.activeColumns | parse:context | async;"></tr>
      </ng-container>
      <ng-container *ngSwitchCase="'footer'">
        <tr mat-footer-row *matHeaderRowDef="rowDef.activeColumns | parse:context | async; sticky: rowDef.sticky"></tr>
      </ng-container>
    </ng-container>
  </ng-container>
  <tr mat-row *matRowDef="let row; columns: input.mainDataRow.activeColumns | parse:context | async;" (click)="onRowClick($event, row)"> </tr>
</table>

<ng-template #expressionOrWidget let-widget="widget" let-context="context">
  <ng-container *ngIf="isPrimitive(widget); else expressionTemplate">
    {{widget}}
  </ng-container>
  <ng-template #expressionTemplate>
    <ng-container *ngIf="isExpression(widget); else widgetTemplate">
      {{ widget | parse: context | async }}
    </ng-container>
  </ng-template>
  <ng-template #widgetTemplate>
    <ng-container
      [dynamicWidget]="widget"
      [context]="context"
    ></ng-container>
  </ng-template>
</ng-template>
