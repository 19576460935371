import { ChangeDetectionStrategy, Component, Injector, ViewEncapsulation } from '@angular/core';
import { Register } from '@trackback/ng-common';
import { TextBoxInput, TextBoxOutput } from '@trackback/widgets';
import { BaseFormFieldWidgetComponent } from '../base-form-field-widget.component';


@Register('TextBox')
@Component({
  selector: 'tb-text-box',
  templateUrl: './text-box.component.html',
  styleUrls: ['./text-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class TextBoxComponent extends BaseFormFieldWidgetComponent<TextBoxInput, TextBoxOutput> {
  constructor(injector: Injector) {
    super(injector);
  }

  /**
   * @see {@link https://ux.stackexchange.com/questions/95336/how-long-should-the-debounce-timeout-be}
   */
  defaultDebounceTime() {
    return 500;
  }

  onEnter(event: KeyboardEvent) {
    if (this.input.enterAction) {
      this.dispatchActionsPromise(this.input.enterAction);
    }
  }

  onEscape(event: KeyboardEvent) {
    if (this.input.escapeAction) {
      this.dispatchActionsPromise(this.input.escapeAction);
    }
  }
}
