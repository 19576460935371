<mat-progress-spinner
  *ngIf="(input.diameter | parse:context | async) as diameter; else default"
  [mode]="input.mode | parse:context | async"
  [color]="color"
  [diameter]="diameter"
  [strokeWidth]="input.strokeWidth | parse:context | async"
  [value]="input.value | parse:context | async"
  [matTooltip]="tooltipText"
  [matTooltipPosition]="tooltipPosition"
></mat-progress-spinner>

<ng-template #default>
  <mat-progress-spinner
    [mode]="input.mode | parse:context | async"
    [color]="color"
    [strokeWidth]="input.strokeWidth | parse:context | async"
    [value]="input.value | parse:context | async"
    [matTooltip]="tooltipText"
    [matTooltipPosition]="tooltipPosition"
  ></mat-progress-spinner>
</ng-template>
