import { isEqual } from 'lodash-es';
import type { AllEqualInExpression } from "@trackback/widgets";
import { createSimpleNamedExpressionResolver } from "../types";

export const equalResolver =
  createSimpleNamedExpressionResolver<AllEqualInExpression>(
    "equal",
    (values) => {
      for (let i = 0; i < values.length - 1; i++) {
        for (let j = i + 1; j < values.length; j++) {
          const a = values[i];
          const b = values[j];

          if (typeof a === "number" || typeof b === "number") {
            if (Number(a) !== Number(b)) {
              return false;
            }
          } else if (!isEqual(a, b)) {
            return false;
          }
        }
      }
      return true;
    }
  );
