import {ChangeDetectionStrategy, Component, Injector, ViewEncapsulation} from '@angular/core';
import {Register} from '@trackback/ng-common';
import { TabbedPageInput, TabbedPageOutput } from '@trackback/widgets';
import {map} from 'rxjs/operators';
import { BasePageWidgetComponent } from '../base-page-widget.component';

@Register('TabbedPage')
@Component({
  selector: 'tb-tabbed-page',
  templateUrl: './tabbed-page.component.html',
  styleUrls: ['./tabbed-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class TabbedPageComponent extends BasePageWidgetComponent<TabbedPageInput, TabbedPageOutput> {

  constructor(injector: Injector) {
    super(injector);
  }

  public readonly _headerHeight$ = this._toolbarHeight$.pipe(
    map(toolbarHeight => toolbarHeight + (this.input.tabsWidget ? 48 : 0))
  );

}
