import { ChangeDetectionStrategy, Component, Injector, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { Register } from '@trackback/ng-common';
import { ToolbarInput, ToolbarOutput } from '@trackback/widgets';
import { BaseWidgetComponent } from '../base-widget.component';

@Register('Toolbar')
@Component({
  selector: 'tb-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ToolbarComponent extends BaseWidgetComponent<ToolbarInput, ToolbarOutput> implements OnInit, OnDestroy {

  color: ThemePalette | null;

  constructor(injector: Injector) {
    super(injector);
  }

  async ngOnInit() {
    // Skip Registration
    this.init();
    this.parse(this.input.toolbarColor)
      .subscribe(color => {
        if (color === 'transparent') {
          this.color = null;
          this.addStyleClasses('transparent');
        } else {
          this.removeStyleClasses('transparent');
          this.color = color as ThemePalette;
        }
        this._cd.markForCheck();
      });
  }

  ngOnDestroy(): void {
    // Skip De-registration
    this.destroy();
  }
}