import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { Select } from "@ngxs/store";
import { LayoutState, Register } from "@trackback/ng-common";
import {
  LocalActionModel,
  SelectFieldInput,
  SelectFieldOutput,
} from "@trackback/widgets";
import { isEqual } from "lodash-es";
import { Observable, of } from "rxjs";
import {
  distinctUntilChanged,
  map,
  shareReplay,
  switchMap,
  takeUntil,
} from "rxjs/operators";
import { BaseFormFieldWidgetComponent } from "../base-form-field-widget.component";

@Register("SelectField")
@Component({
  selector: "tb-select-field",
  templateUrl: "./select-field.component.html",
  styleUrls: ["./select-field.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SelectFieldComponent
  extends BaseFormFieldWidgetComponent<SelectFieldInput, SelectFieldOutput>
  implements OnInit
{
  @Select(LayoutState.isSize("large"))
  isLarge$: Observable<boolean>;

  public readonly parsedOptions$ = this.input$.pipe(
    switchMap((input) =>
      input && input.options ? this.parse(input.options) : []
    ),
    map((options) =>
      Array.isArray(options) ? options.filter((option) => !!option) : []
    ),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );
  public checked = new FormControl<boolean>(false);
  public selectFieldOptions;

  removePreviousValue() {
    this.updateOutput({ value: "" });
  }

  formatOutputValue(next?: any) {
    if (this.input.multiple && this.input.allSelectedAsNull && this._formControl.value?.length === this.selectFieldOptions?.length) {
      return null;
    }
    return next;
  }

  async ngOnInit() {
    await super.ngOnInit();

    const optionKeys = this.getOptionsKeyValues();

    if (this.input.multiple) {
      this.checked.valueChanges
        .pipe(takeUntil(this.destroyed$))
        .subscribe((newValue) => this.updateOutput({ allSelected: newValue }));
    }

    this.parse(this.input.options).subscribe((parsedOptions) => {
      this.selectFieldOptions = parsedOptions;
      if (this.input.defaultValue) {
        this.parse(this.input.defaultValue)
          .pipe(distinctUntilChanged(isEqual))
          .subscribe((parsedDefaultValue) => {
            this.updateLabelByValue(parsedDefaultValue);
            if (this.input.multiple && parsedDefaultValue === "all") {
              this.checked.patchValue(true, {
                emitEvent: true
              });
              this._formControl.patchValue(
                this.selectFieldOptions.map(
                  (Option) => Option[optionKeys.valueKey]
                )
              );
            }
          });
      }
    });

    this.parsedOptions$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((parsedOptions) =>
        this.updateOutput({
          options: parsedOptions,
        } as Partial<SelectFieldOutput>)
      );
  }

  toggleAllSelection() {
    if (!this.checked.value) {
      const optionKeys = this.getOptionsKeyValues();
      this._formControl.patchValue(
        this.selectFieldOptions.map((Option) => Option[optionKeys.valueKey])
      );
    } else {
      this._formControl.patchValue([]);
    }
  }

  onClickOption() {
    this.checked.patchValue(
      this._formControl.value.length === this.selectFieldOptions.length,
      {
        emitEvent: true
      }
    );
  }

  updateLabelByValue(value: any) {
    const optionKeys = this.getOptionsKeyValues();
    const index = this.selectFieldOptions.findIndex(
      (e) => e[optionKeys.valueKey] === value
    );

    if (this.selectFieldOptions[index] != null) {
      this.updateOutput({
        label: this.selectFieldOptions[index][optionKeys.labelKey],
      });
    }
  }

  handleSetValueAction(action: LocalActionModel) {
    if (action.payload === "all" && this.input.multiple) {
      const optionKeys = this.getOptionsKeyValues();
      this._formControl.patchValue(
        this.selectFieldOptions.map((Option) => Option[optionKeys.valueKey])
      );
      this.checked.patchValue(true, {
        emitEvent: true
      });
      this._cd.detectChanges();
    } else {
      this._value = action.payload;
    }
    return of(null);
  }

  /**
   * Gets the options object keys for the label and the value. Allowing the consumer
   * to change the values at creation time, e.g not enforcing the keys to be label
   * and value.
   *
   *
   * @returns {{ labelKey: string, valueKey: string }}
   */
  private getOptionsKeyValues(): { labelKey: string; valueKey: string } {
    return {
      labelKey:
        this.input.optionsLabelKey != null
          ? this.input.optionsLabelKey
          : "label",
      valueKey:
        this.input.optionsValueKey != null
          ? this.input.optionsValueKey
          : "value",
    };
  }
}
