import { ChangeDetectionStrategy, Component, Injector, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ActivatedRoute } from '@angular/router';
import { Register } from '@trackback/ng-common';
import { HyperlinkInput, HyperlinkOutput, MatColorDefinitionModel } from '@trackback/widgets';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { BaseWidgetComponent } from '../base-widget.component';

@Register('Hyperlink')
@Component({
  selector: 'tb-hyperlink',
  templateUrl: './hyperlink.component.html',
  styleUrls: ['./hyperlink.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class HyperlinkComponent extends BaseWidgetComponent<HyperlinkInput, HyperlinkOutput> implements OnInit, OnDestroy {

  color: ThemePalette | null;

  private readonly activatedRoute = this.injector.get(ActivatedRoute);

  constructor(private readonly injector: Injector) {
    super(injector);
  }

  protected setColorActive(type: 'foreground' | 'background', color: MatColorDefinitionModel, flag: boolean) {
    this.color = typeof color === 'object' ? color.palette : color;
    this._cd.markForCheck();
  }

  async ngOnInit() {
    await super.ngOnInit();

    combineLatest([this.parse(this.input.url), this.activatedRoute.url]).pipe(
      map(([parsedUrl, segments]) => {
        const activeUrl = '/' + (segments.length > 0 ? segments.map(segment => segment.path).join('/') : '');
        return activeUrl === parsedUrl;
      }),
      distinctUntilChanged(),
      takeUntil(this.destroyed$)
    ).subscribe(active => {
      this.updateOutput({
        active
      });
    });
  }

  ngOnDestroy(): void {
    // Skip De-registration
    this.destroy();
  }
}
