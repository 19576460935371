import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Register } from '@trackback/ng-common';
import { TexthtmlInput, TexthtmlOutput } from '@trackback/widgets';
import { BaseWidgetComponent } from '../base-widget.component';

@Register('Texthtml')
@Component({
  selector: 'tb-texthtml',
  templateUrl: './texthtml.component.html',
  styleUrls: ['./texthtml.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush, // Widget should be optimised for performance
  encapsulation: ViewEncapsulation.None // Disabling view encapsulation allows for easier styling
})
export class TexthtmlComponent extends BaseWidgetComponent<TexthtmlInput, TexthtmlOutput> implements OnInit, OnDestroy {
}
