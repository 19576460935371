import { Observable, Subscription } from 'rxjs';
import type { HttpExpression } from '@trackback/widgets';
import { createNamedExpressionResolver } from './../types';

export const createRemoteResolver = (
  get: (url: string) => Observable<unknown>
) =>
  createNamedExpressionResolver<HttpExpression>(
    'remote',
    (parser, options, onData, [url]) => {
      let previousUrl = '';
      let subscription: Subscription;
      let latestUrlValue: string;
      let timer: number | null = null;
      const executeRemoteCallback = () => {
        timer && clearInterval(timer);
        timer = null;
        const parsedUrl = latestUrlValue && latestUrlValue.trim();
        if (typeof parsedUrl === 'string' && parsedUrl !== previousUrl) {
          if (subscription && !subscription.closed) {
            subscription.unsubscribe();
            options.loadingStateCounter &&
              options.loadingStateCounter.decrement();
          }
          previousUrl = parsedUrl;
          options.loadingStateCounter &&
            options.loadingStateCounter.increment();
          subscription = get(parsedUrl).subscribe(
            onData.next,
            onData.error,
            () => {
              options.loadingStateCounter &&
                options.loadingStateCounter.decrement();
            }
          );
        }
      };
      const unsubscribeUrlParsing = parser.parseWithCallback(
        url,
        {
          error: onData.error,
          next: (next) => {
            latestUrlValue = next;
            if (timer === null) {
              timer = setInterval(executeRemoteCallback, 10) as any; // TODO: NodeJS types conflict with Browser types
            }
          },
        },
        options
      );
      return () => {
        if (timer) {
          clearInterval(timer);
        }
        if (subscription && !subscription.closed) {
          subscription.unsubscribe();
          options.loadingStateCounter &&
            options.loadingStateCounter.decrement();
        }
        if (unsubscribeUrlParsing) {
          unsubscribeUrlParsing();
        }
      };
    }
  );
