import { Type } from "@angular/core";
import { flatten, uniq } from "lodash-es";
import {
  AppError,
  DEFAULT_ERROR_TRANSLATION_KEYS,
} from "../models/error.model";

type LoaderFunction = () => Promise<{ default: any }>;

/**
 * Contains all registered widget for name lookup.
 *
 * Utilises the @Register decorator for propagation.
 */
export const TYPE_REGISTRY = {};
export const TYPE_GROUPS = {};
export const ASYNC_TYPE_REGISTRY: Record<string, LoaderFunction> = {};

export function Register(
  typeName: string | string[],
  groups?: string | string[]
): ClassDecorator {
  return function (constructor: any) {
    if (Array.isArray(typeName)) {
      typeName.forEach((tn) => {
        TYPE_REGISTRY[tn] = constructor;
        addGroups(tn, groups);
      });
    } else {
      TYPE_REGISTRY[typeName] = constructor;
      addGroups(typeName, groups);
    }
    constructor.registeredAs = typeName;
  };
}

export function registerAsync(typeName: string, loader: LoaderFunction) {
  ASYNC_TYPE_REGISTRY[typeName] = loader;
}

function addGroups(typeName: string, groups?: string | string[]) {
  if (Array.isArray(groups)) {
    groups.forEach((group) => pushToProperty(TYPE_GROUPS, group, typeName));
  } else if (groups) {
    pushToProperty(TYPE_GROUPS, groups, typeName);
  }
}

function pushToProperty(obj, key, value) {
  if (!obj[key]) {
    obj[key] = [];
  }
  obj[key].push(value);
}

export function getType<T>(typ: string): Type<T> {
  const componentClass = TYPE_REGISTRY[typ];
  if (componentClass) {
    return componentClass;
  } else {
    throw new AppError(
      `widgets/unknown-type`,
      DEFAULT_ERROR_TRANSLATION_KEYS.APPLICATION_ERROR,
      `No type definition available for ${typ || "undefined"}`
    );
  }
}

export function getTypeAsync<T>(type: string): Promise<Type<T>> {
  if (type in TYPE_REGISTRY) {
    return Promise.resolve(TYPE_REGISTRY[type]);
  } else if (type in ASYNC_TYPE_REGISTRY) {
    return ASYNC_TYPE_REGISTRY[type]().then(mod => {
      const cmp = mod.default;
      TYPE_REGISTRY[type] = cmp;
      return cmp;
    });
  }

  throw new AppError(`widgets/unknown-type`, DEFAULT_ERROR_TRANSLATION_KEYS.APPLICATION_ERROR,
      `No type definition available for ${type || 'undefined'}`);
}

export function getTypesInGroup(groups: string | string[]): string[] {
  if (Array.isArray(groups)) {
    return uniq(flatten(groups.map((group) => getTypesInGroup(group))));
  }
  return TYPE_GROUPS[groups] || [];
}

export function isTypeInGroup(type: string, group: string) {
  return getTypesInGroup(group).includes(type);
}
