import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { APP_CONFIG, AppConfigModel, ErrorModel } from "@trackback/ng-common";
import { produce } from "immer";
import { throwError } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { TranslationMap } from "../models/translation-map.model";
import { TranslationRegistry } from "../models/translation-registry.model";
import * as Actions from "./translation.actions";
import {
  LoadTranslationFail,
  LoadTranslationSuccess,
  SetLanguage,
} from "./translation.actions";

export interface TranslationStateModel {
  languageCode: string | undefined;
  activeTranslationKeys: string[];
  translations: TranslationRegistry;
  pendingLanguageCode: string | undefined | null;
  translationLoadError: ErrorModel | undefined | null;
}

export const InitialTranslationStateModel: TranslationStateModel = {
  languageCode: undefined,
  translations: {},
  activeTranslationKeys: [],
  pendingLanguageCode: undefined,
  translationLoadError: undefined,
};

export const DEFAULT_FALLBACK_LANGUAGE_CODE = "en";

@State<TranslationStateModel>({
  name: "translation",
  defaults: InitialTranslationStateModel,
})
@Injectable()
export class TranslationState {
  private readonly TRANSLATION_API_URL = `${this.config.API_PROTOCOL}://${this.config.API_URL}/lang`;

  @Selector()
  static getLoadingError(state: TranslationStateModel) {
    return state.translationLoadError;
  }

  @Selector()
  static getLanguageCode(state: TranslationStateModel) {
    return state.languageCode;
  }

  @Selector()
  static getPendingLanguageCode(state: TranslationStateModel) {
    return state.pendingLanguageCode;
  }

  @Selector()
  static getActiveTranslationKeys(state: TranslationStateModel) {
    return state.activeTranslationKeys;
  }

  @Selector([TranslationState.getPendingLanguageCode])
  static isLoading(_, languageCode: string) {
    return !!languageCode;
  }

  @Selector([TranslationState.getLanguageCode])
  static isInitialized(_, languageCode: string) {
    return !!languageCode;
  }

  @Selector([TranslationState.getLanguageCode])
  static getCurrentTranslations(
    state: TranslationStateModel,
    languageCode: string
  ): TranslationMap {
    return state.translations[languageCode];
  }

  @Action(Actions.SetLanguage, { cancelUncompleted: true })
  setLanguage(
    { dispatch, getState, patchState }: StateContext<TranslationStateModel>,
    { languageCode }: Actions.SetLanguage
  ) {
    // Do nothing if new language is current language
    if (getState().languageCode === languageCode) {
      return;
    }

    // If the requested translations are already present, switch language immediately
    const currentKeys = getState().translations[languageCode]
      ? Object.keys(getState().translations[languageCode])
      : [];
    if (
      getState().activeTranslationKeys.every(
        (key) => currentKeys.indexOf(key) >= 0
      )
    ) {
      return patchState({
        translationLoadError: null,
        languageCode,
      });
    } else {
      patchState({
        translationLoadError: null,
        pendingLanguageCode: languageCode,
      });

      return this.getTranslations(
        languageCode,
        getState().activeTranslationKeys
      ).pipe(
        switchMap((translations) =>
          dispatch(new LoadTranslationSuccess(languageCode, translations))
        ),
        catchError((_) => {
          const languageParts = languageCode.split("-");
          const hasCountryCode = languageParts.length === 2;
          if (hasCountryCode) {
            languageCode = languageParts[0];
            return dispatch(new SetLanguage(languageCode));
          } else if (languageParts[0] !== DEFAULT_FALLBACK_LANGUAGE_CODE) {
            return dispatch(new SetLanguage(DEFAULT_FALLBACK_LANGUAGE_CODE));
          } else {
            const error = {
              name: "lang/translation-load-failed",
              message: "translation_load_failed",
            } as ErrorModel;
            dispatch(new LoadTranslationFail(error));
            return throwError(error);
          }
        })
      );
    }
  }

  @Action(Actions.LoadTranslationSuccess)
  loadTranslationSuccess(
    { setState }: StateContext<TranslationStateModel>,
    { languageCode, translations }: Actions.LoadTranslationSuccess
  ) {
    return setState(
      produce((draft) => {
        draft.languageCode = languageCode;
        draft.pendingLanguageCode = null;
        draft.activeTranslationKeys = Object.keys(translations);
        if (draft.translations && draft.translations[languageCode]) {
          Object.assign(draft.translations[languageCode], translations);
        } else {
          draft.translations[languageCode] = translations;
        }
      })
    );
  }

  @Action(Actions.LoadTranslationFail)
  loadTranslationFail(
    { patchState }: StateContext<TranslationStateModel>,
    { error }: Actions.LoadTranslationFail
  ) {
    return patchState({
      translationLoadError: error,
      pendingLanguageCode: null,
    });
  }

  constructor(
    @Inject(APP_CONFIG) private readonly config: AppConfigModel,
    private readonly http: HttpClient
  ) {}

  private getTranslations(languageCode: string, keys: string[] = []) {
    return this.http.get<TranslationMap>(
      `${this.TRANSLATION_API_URL}/${languageCode}?keys=${encodeURIComponent(
        keys.join(",")
      )}`
    );
  }
}
