import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, Injector, Optional } from "@angular/core";
import { Store } from "@ngxs/store";
import { APP_CONFIG, AppConfigModel } from "@trackback/ng-common";
import { TranslationState } from "@trackback/ng-lang";
import { createAliasResolver } from "../expressions/lib/expressions/functions/aliases.resolver";
import { andResolver } from "../expressions/lib/expressions/functions/and.resolver";
import { arrayIndexResolver } from "../expressions/lib/expressions/functions/array-index.resolver";
import { changeCaseResolver } from "../expressions/lib/expressions/functions/changecase.resolver";
import { coalesceResolver } from "../expressions/lib/expressions/functions/coalesce.resolver";
import { concatResolver } from "../expressions/lib/expressions/functions/concat.resolver";
import { contextResolver } from "../expressions/lib/expressions/functions/context.resolver";
import { distinctUntilChangedResolver } from "../expressions/lib/expressions/functions/distinct-until-changed.function";
import { divideResolver } from "../expressions/lib/expressions/functions/divide.resolver";
import { encodeURIResolver } from "../expressions/lib/expressions/functions/encodeURI.resolver";
import { equalResolver } from "../expressions/lib/expressions/functions/equal.resolver";
import { filterResolver } from "../expressions/lib/expressions/functions/filter.resolver";
import { floorResolver } from "../expressions/lib/expressions/functions/floor.resolver";
import { getResolver } from "../expressions/lib/expressions/functions/get.resolver";
import { createGlobalResolver } from "../expressions/lib/expressions/functions/global.resolver";
import { createIdsResolver } from "../expressions/lib/expressions/functions/ids.resolver";
import { ifResolver } from "../expressions/lib/expressions/functions/if.resolver";
import { includesResolver } from "../expressions/lib/expressions/functions/includes.resolver";
import { invertResolver } from "../expressions/lib/expressions/functions/invert.resolver";
import { lessThanResolver } from "../expressions/lib/expressions/functions/less-than.resolver";
import { literalResolver } from "../expressions/lib/expressions/functions/literal.resolver";
import { createLocalWidgetIdResolver } from "../expressions/lib/expressions/functions/local-widget-id.resolver";
import { minResolver } from "../expressions/lib/expressions/functions/min.resolver";
import { modResolver } from "../expressions/lib/expressions/functions/mod.resolver";
import { multiplyResolver } from "../expressions/lib/expressions/functions/multiply.resolver";
import { notResolver } from "../expressions/lib/expressions/functions/not.resolver";
import { createOutputResolver } from "../expressions/lib/expressions/functions/outputs.resolver";
import { regexResolver } from "../expressions/lib/expressions/functions/regex.resolver";
import { createRemoteResolver } from "../expressions/lib/expressions/functions/remote.resolver";
import { replaceResolver } from "../expressions/lib/expressions/functions/replace.resolver";
import { roundResolver } from "../expressions/lib/expressions/functions/round.resolver";
import { searchResolver } from "../expressions/lib/expressions/functions/search.resolver";
import { searchableResolver } from "../expressions/lib/expressions/functions/searchable.resolver";
import { toHoursMinsResolver } from "../expressions/lib/expressions/functions/seconds-to-hours-mins.resolver";
import { sizeResolver } from "../expressions/lib/expressions/functions/size.resolver";
import { sortResolver } from "../expressions/lib/expressions/functions/sort.resolver";
import { subtractResolver } from "../expressions/lib/expressions/functions/subtract.resolver";
import { sumResolver } from "../expressions/lib/expressions/functions/sum.resolver";
import { switchResolver } from "../expressions/lib/expressions/functions/switch.resolver";
import { createTranslationResolver } from "../expressions/lib/expressions/functions/translations.resolver";
import { valuesResolver } from "../expressions/lib/expressions/functions/values.resolver";
import mapResolver from "../expressions/lib/expressions/functions/map.resolver";
import {
  Parser,
  createCallbackResolver,
} from "../expressions/lib/expressions/parser";
import {
  WidgetsState,
  WidgetsStateModel,
} from "../state/widgets/widgets.state";
import { createFormatDateResolver } from "../expressions/lib/expressions/functions/format-date.resolver";
import { createFormatDateRelativeResolver } from "../expressions/lib/expressions/functions/format-date-relative.resolver";

/**
 * Service that provides helper functions to asynchronously replace binding expressions with valuesOf.
 */
@Injectable({
  providedIn: "root",
})
export class ParserService {
  private _parser = new Parser();

  constructor(
    private readonly injector: Injector,
    private readonly store: Store,
    @Optional() @Inject(APP_CONFIG) private readonly config?: AppConfigModel
  ) {
    this._parser.registerResolver(
      createAliasResolver(this.store.select(WidgetsState.getAliases))
    );
    this._parser.registerResolver(andResolver);
    this._parser.registerResolver(arrayIndexResolver);
    this._parser.registerResolver(coalesceResolver);
    this._parser.registerResolver(changeCaseResolver);
    this._parser.registerResolver(concatResolver);
    this._parser.registerResolver(contextResolver);
    this._parser.registerResolver(divideResolver);
    this._parser.registerResolver(encodeURIResolver);
    this._parser.registerResolver(equalResolver);
    this._parser.registerResolver(filterResolver);
    this._parser.registerResolver(createFormatDateResolver(this.store.select(TranslationState.getLanguageCode)));
    this._parser.registerResolver(distinctUntilChangedResolver);
    this._parser.registerResolver(getResolver);
    this._parser.registerResolver(
      createGlobalResolver(
        this.store.select(
          (state: WidgetsStateModel) => state as Record<string, any>
        )
      )
    );
    this._parser.registerResolver(
      createIdsResolver(this.store.select(WidgetsState.getWidgetIds))
    );
    this._parser.registerResolver(ifResolver);
    this._parser.registerResolver(includesResolver);
    this._parser.registerResolver(invertResolver);
    this._parser.registerResolver(
      createLocalWidgetIdResolver(
        (options) => (options.context || {})._id as string
      )
    );
    this._parser.registerResolver(
      "map",
      mapResolver
    );
    this._parser.registerResolver(modResolver);
    this._parser.registerResolver(multiplyResolver);
    this._parser.registerResolver(notResolver);
    this._parser.registerResolver(
      createOutputResolver(this.store.select(WidgetsState.getAliasedOutputs))
    );
    this._parser.registerResolver(regexResolver);
    const http = this.injector.get(HttpClient);
    this._parser.registerResolver(createRemoteResolver(http.get.bind(http)));
    this._parser.registerResolver(replaceResolver);
    this._parser.registerResolver(roundResolver);
    this._parser.registerResolver(searchResolver);
    this._parser.registerResolver(searchableResolver);
    this._parser.registerResolver(sizeResolver);
    this._parser.registerResolver(subtractResolver);
    this._parser.registerResolver(sumResolver);
    this._parser.registerResolver(lessThanResolver);
    this._parser.registerResolver(literalResolver);
    this._parser.registerResolver(sortResolver);
    this._parser.registerResolver(switchResolver);
    this._parser.registerResolver(createFormatDateRelativeResolver(this.store.select(TranslationState.getLanguageCode)));
    this._parser.registerResolver(
      createTranslationResolver(
        this.store.select(TranslationState.getCurrentTranslations)
      )
    );
    this._parser.registerResolver(valuesResolver);
    this._parser.registerResolver(minResolver);
    this._parser.registerResolver(floorResolver);
    this._parser.registerResolver(toHoursMinsResolver);
    this._parser.registerResolver(
      createCallbackResolver((object: any) => {
        if (!this.config || !this.config.PRODUCTION) {
          return JSON.stringify(object);
        } else {
          return object;
        }
      }, "stringify")
    );
  }

  public parse = this._parser.parse.bind(this._parser) as Parser["parse"];
  public parseOnce = this._parser.parseOnce.bind(
    this._parser
  ) as Parser["parseOnce"];
}
