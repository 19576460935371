import { LayoutSize } from "../../models/layout/layout-size.model";
import { LayoutOrientation } from "../../models/layout/layout-orientation.model";
import { Register } from "../../utils/type-registry";

@Register("SetActiveClientId")
export class SetActiveClientId {
  static readonly type = "[Shared.Layout] Set Active ClientId";

  constructor(public clientId: number) {}
}
export class SetSize {
  static readonly type = "[Shared.Layout] Set Size";

  constructor(public size: LayoutSize) {}
}

export class SetOrientation {
  static readonly type = "[Shared.Layout] Set Orientation";

  constructor(public orientation: LayoutOrientation) {}
}
