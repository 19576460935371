import { NgxMatFileInputModule } from "@angular-material-components/file-input";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule, Type } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatLegacyCheckboxModule as MatCheckboxModule } from "@angular/material/legacy-checkbox";
import { MatLegacyChipsModule as MatChipsModule } from "@angular/material/legacy-chips";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyListModule as MatListModule } from "@angular/material/legacy-list";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacyPaginatorModule as MatPaginatorModule } from "@angular/material/legacy-paginator";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatLegacySlideToggleModule as MatSlideToggleModule } from "@angular/material/legacy-slide-toggle";
import { MatLegacySnackBarModule as MatSnackBarModule } from "@angular/material/legacy-snack-bar";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatLegacyTooltipModule as MatTooltipModule } from "@angular/material/legacy-tooltip";
import { MatTreeModule } from "@angular/material/tree";
import { RouterModule } from "@angular/router";
import { NGXS_PLUGINS, NgxsModule } from "@ngxs/store";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { DynamicWidgetDirective } from "./directives/dynamic-widget.directive";
import {
  ACTION_DISPATCHER,
  ActionDispatcherService,
} from "./models/action-dispatcher.model";
import { ParsePipe } from "./pipes/parse.pipe";
import { DispatcherService } from "./services/dispatcher.service";
import { NgxsWidgetPluginService } from "./services/ngxs-widget-plugin.service";
import { WidgetsState } from "./state/widgets/widgets.state";
import { AudioComponent } from "./widgets/audio/audio.component";
import { ButtonComponent } from "./widgets/button/button.component";
import { CheckBoxFieldComponent } from "./widgets/check-box/check-box-field.component";
import { DataTableComponent } from "./widgets/data-table/data-table.component";
import { DatepickerFieldComponent } from "./widgets/datepicker-field/datepicker-field.component";
import { DefaultPageComponent } from "./widgets/default-page/default-page.component";
import { DialogComponent } from "./widgets/dialog/dialog.component";
import { DirectionalLayoutComponent } from "./widgets/directional-layout/directional-layout.component";
import { ExpansionPanelComponent } from "./widgets/expansion-panel/expansion-panel.component";
import { FileUploadFieldComponent } from "./widgets/file-upload-field/file-upload-field.component";
import { HyperlinkComponent } from "./widgets/hyperlink/hyperlink.component";
import { IconComponent } from "./widgets/icon/icon.component";
import { ImageComponent } from "./widgets/image/image.component";
import { LabelRowComponent } from "./widgets/label-row/label-row.component";
import { NavigationListComponent } from "./widgets/navigation-list/navigation-list.component";
import { ProgressSpinnerComponent } from "./widgets/progress-spinner/progress-spinner.component";
import { RadioGroupFieldComponent } from "./widgets/radio-group-field/radio-group-field.component";
import { SearchBoxFieldComponent } from "./widgets/search-box-field/search-box-field.component";
import { SelectFieldComponent } from "./widgets/select-field/select-field.component";
import { SlideToggleFieldComponent } from "./widgets/slide-toggle-field/slide-toggle-field.component";
import { TabbedPageComponent } from "./widgets/tabbed-page/tabbed-page.component";
import { TabsComponent } from "./widgets/tabs/tabs.component";
import { TextBoxComponent } from "./widgets/text-box/text-box.component";
import { TextFieldComponent } from "./widgets/text-field/text-field.component";
import { TextComponent } from "./widgets/text/text.component";
import { TextareaFieldComponent } from "./widgets/textarea-field/textarea-field.component";
import { TexthtmlComponent } from "./widgets/texthtml/texthtml.component";
import { ToolbarComponent } from "./widgets/toolbar/toolbar.component";
import { TreeTableComponent } from "./widgets/tree-table/tree-table.component";
import { AccordionComponent } from "./wrapper-widgets/accordion/accordion.component";
import { PaginatorComponent } from "./wrapper-widgets/paginator/paginator.component";

const EXPORTS = [
  ParsePipe,
  RadioGroupFieldComponent,
  LabelRowComponent,
  TextareaFieldComponent,
  SelectFieldComponent,
  TabsComponent,
  DirectionalLayoutComponent,
  ButtonComponent,
  TextFieldComponent,
  HyperlinkComponent,
  DynamicWidgetDirective,
  DialogComponent,
  TextComponent,
  TextBoxComponent,
  DefaultPageComponent,
  ToolbarComponent,
  DataTableComponent,
  TreeTableComponent,
  IconComponent,
  DatepickerFieldComponent,
  NavigationListComponent,
  TabbedPageComponent,
  ProgressSpinnerComponent,
  AudioComponent,
  TexthtmlComponent,
  ImageComponent,
  ProgressSpinnerComponent,
  AccordionComponent,
  ExpansionPanelComponent,
  PaginatorComponent,
  SearchBoxFieldComponent,
  CheckBoxFieldComponent,
  SlideToggleFieldComponent,
  FileUploadFieldComponent,
];

@NgModule({
  declarations: EXPORTS,
  imports: [
    CommonModule,
    RouterModule,
    NgxsModule.forFeature([WidgetsState]),
    ReactiveFormsModule,
    MatChipsModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatTabsModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatSidenavModule,
    MatToolbarModule,
    MatTableModule,
    MatDatepickerModule,
    MatListModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatTreeModule,
    MatTooltipModule,
    MatExpansionModule,
    MatPaginatorModule,
    NgxMatSelectSearchModule,
    MatMenuModule,
    MatSlideToggleModule,
    ScrollingModule,
    NgxMatFileInputModule,
  ],
  exports: EXPORTS,
  bootstrap: [
    RadioGroupFieldComponent,
    LabelRowComponent,
    TextareaFieldComponent,
    TextBoxComponent,
    TreeTableComponent,
    SelectFieldComponent,
    TabsComponent,
    DirectionalLayoutComponent,
    ButtonComponent,
    TextFieldComponent,
    HyperlinkComponent,
    DialogComponent,
    TextComponent,
    DefaultPageComponent,
    ToolbarComponent,
    DataTableComponent,
    IconComponent,
    DatepickerFieldComponent,
    NavigationListComponent,
    TabbedPageComponent,
    ProgressSpinnerComponent,
    AudioComponent,
    TexthtmlComponent,
    ImageComponent,
    AccordionComponent,
    ExpansionPanelComponent,
    PaginatorComponent,
    SearchBoxFieldComponent,
    CheckBoxFieldComponent,
    SlideToggleFieldComponent,
    FileUploadFieldComponent,
  ],
})
export class NgWidgetsModule {
  static forRoot(options?: {
    actionDispatcher?: Type<ActionDispatcherService>;
  }): ModuleWithProviders<NgWidgetsModule> {
    return {
      ngModule: NgWidgetsModule,
      providers: [
        {
          provide: ACTION_DISPATCHER,
          useClass: (options || {}).actionDispatcher || DispatcherService,
        },
        {
          provide: NGXS_PLUGINS,
          useClass: NgxsWidgetPluginService,
          multi: true,
        },
      ],
    } as ModuleWithProviders<NgWidgetsModule>;
  }
}
