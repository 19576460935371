import type { ToHoursMinsExpression } from '@trackback/widgets';
import { createSimpleNamedExpressionResolver } from '../types';

export const toHoursMinsResolver =
  createSimpleNamedExpressionResolver<ToHoursMinsExpression>(
    'toHoursMins',
    (seconds) => {
      const h = Math.floor(seconds / 3600);
      const m = Math.floor((seconds % 3600) / 60);
      const hours = h < 10 ? String('0' + h).slice(-2) : h;
      const mins = m < 10 ? String('0' + m).slice(-2) : m;
      return hours + ':' + mins;
    }
  );
