import { APP_CONFIG, Register } from '@trackback/ng-common';
import { ForeachInputModel, WidgetInput } from '@trackback/widgets';
import { Observable, combineLatest, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { WidgetDefinitionTuple } from '../models/widget-input.model';
import { ParserService } from '../services/parser.service';
import { STRUCTURAL_WIDGET_TYPE_GROUP } from './index';
import { WidgetResolver } from './widget-resolver';

@Register('foreach', STRUCTURAL_WIDGET_TYPE_GROUP)
export class ForeachWidget extends WidgetResolver<
  ForeachInputModel<WidgetInput, Record<string, unknown>>
> {
  private readonly _parser = this._injector.get(ParserService);
  private readonly _config = this._injector.get(APP_CONFIG, null);

  getState(
    context?: Record<string, unknown>
  ): Observable<WidgetDefinitionTuple[]> {
    return this._parser
      .parse(this._input.in, {
        context: context,
        log:
          !this._config || !this._config.PRODUCTION ? console.log : undefined,
      })
      .pipe(
        switchMap(objects =>
          objects && Array.isArray(objects) && objects.length > 0
            ? combineLatest(
                objects.map((obj, idx) => {
                  const ctx = {
                    ...context,
                    [this._input.dataAlias || 'foreachData']: obj,
                    [this._input.metaAlias || 'foreachMeta']: {
                      index: idx,
                      first: idx === 0,
                      last: idx === objects.length - 1,
                      odd: idx % 2 !== 0,
                      even: idx % 2 === 0,
                      total: objects.length,
                    },
                  };
                  return this._parser
                    .parse(this._input.template, {
                      context: ctx,
                      log:
                        !this._config || !this._config.PRODUCTION
                          ? console.log
                          : undefined,
                      keyWhitelist: ['id', 'alias'],
                      maxParseDepth: this._input.templateMaxParseDepth,
                    })
                    .pipe(
                      map(
                        parsedTemplate =>
                          [parsedTemplate, ctx] as WidgetDefinitionTuple
                      )
                    );
                })
              )
            : of([])
        )
      );
  }
}
