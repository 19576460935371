import { ChangeDetectionStrategy, Component, Injector, ViewEncapsulation } from '@angular/core';
import { Register } from '@trackback/ng-common';
import { TextareaFieldInput, TextareaFieldOutput } from '@trackback/widgets';
import { BaseFormFieldWidgetComponent } from '../base-form-field-widget.component';

@Register('TextareaField')
@Component({
  selector: 'tb-textarea-field',
  templateUrl: './textarea-field.component.html',
  styleUrls: ['./textarea-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,

})
export class TextareaFieldComponent extends BaseFormFieldWidgetComponent<TextareaFieldInput, TextareaFieldOutput>  {

  constructor(injector: Injector) {
    super(injector);
  }

  /**
   * @see {@link https://ux.stackexchange.com/questions/95336/how-long-should-the-debounce-timeout-be}
   */
  defaultDebounceTime() {
    return 500;
  }

}
