import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgWidgetsModule } from '@trackback/ng-widgets';
import { map } from 'rxjs/operators';

@Component({
  selector: 'tb-dynamic-widget-page',
  template: `<ng-container [dynamicWidget]="widget$ | async"></ng-container>`,
  styles: [
    `
      :host {
        flex: 1;
        display: flex;
        flex-direction: column;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, NgWidgetsModule],
})
export class DynamicWidgetPageComponent {
  readonly widget$ = this.route.data.pipe(map(data => data.widget));

  constructor(readonly route: ActivatedRoute) {}
}
