import { Component, ChangeDetectionStrategy, ViewEncapsulation, OnInit, ViewChild, Injector } from "@angular/core";
import { ThemePalette } from "@angular/material/core";
import { MatLegacyTabGroup as MatTabGroup } from "@angular/material/legacy-tabs";
import { Register } from "@trackback/ng-common";
import { TabsInput, TabsOutput, TabModel, MatColorDefinitionModel, LocalActionModel } from "@trackback/widgets";
import { of } from "rxjs";
import { BaseWidgetComponent } from "../base-widget.component";


@Register('Tabs')
@Component({
  selector: 'tb-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,

})
export class TabsComponent extends BaseWidgetComponent<TabsInput, TabsOutput> implements OnInit {

  @ViewChild(MatTabGroup) _tabGroup: MatTabGroup;

  _tabs: TabModel[] = [];
  color: ThemePalette | null;
  backgroundColor: ThemePalette | null;

  protected setColorActive(type: 'foreground' | 'background', color: MatColorDefinitionModel, flag: boolean) {
    if (type === 'foreground') {
      this.color = color.palette || null;
    } else {
      this.backgroundColor = color.palette || null;
    }
    this._cd.markForCheck();
  }

  constructor(injector: Injector) {
    super(injector);
  }

  handleToggleTabAction(action) {
    this._tabGroup.selectedIndex = action.payload;
    this._cd.markForCheck();
    return of(null);
  }

  handleAddTabAction(action: LocalActionModel) {
    if (action.payload) {
      const selectionBehaviour = (action.payload as Record<string, any>).selection as string | undefined;
      const tab = (action.payload as Record<string, any>).tab as TabModel;
      const payloadIndex = (action.payload as Record<string, any>).index;
      const index = typeof payloadIndex === 'number' ? payloadIndex : this._tabs.length;
      const tabs = this._tabs;
      tabs.splice(index, 0, tab);
      this._tabs = tabs;
      switch (selectionBehaviour) {
        case 'new':
          this._tabGroup.selectedIndex = index;
          break;
        case 'old':
        case 'prev':
        case 'previous':
          // DO NOTHING
          break;
        default:
          this._tabGroup.selectedIndex = 0;
      }
      this._cd.markForCheck();
    }

    return of(null);
  }

  trackByFn(index: number, item: TabModel) {
    return item.widget.id;
  }

  async ngOnInit() {
    await super.ngOnInit();
    this._tabs = [ ...this.input.tabs ];
    switch (this.input.tabsAlignment) {
      case 'start':
        this.addStyleClasses('align-start');
        break;
      case 'center':
        this.addStyleClasses('align-center');
        break;
      case 'end':
        this.addStyleClasses('align-end');
        break;
      case 'stretch':
        this.addStyleClasses('align-stretch');
        break;
    }
  }
}
