/**
 * Buttons provide a surface which dispatches an action on click.
 *
 * Either a text and/or an icon should be provided.
 *
 * This implementation follows the [Official Specification](https://material.io/design/components/buttons.html) and
 * is based on the [Angular Material Implementation](https://material.angular.io/components/button/examples).
 *
 * @module widgets/button
 * @example A simple icon button which opens a snackbar
 * ```
 * {
 *   type: 'Button',
 *   style: 'icon',
 *   icon: 'create',
 *   clickAction: {
 *             type: 'global',
 *             name: 'OpenSimpleSnackbar',
 *             payload: {
 *               text: 'Button clicked'
 *             }
 *           }
 * }
 * ```
 */

/** Required comment to display module description, wont be included in the documentation */

import { ChangeDetectionStrategy, Component, Injector, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { Register } from '@trackback/ng-common';
import { ButtonInput, ButtonOutput, LocalActionModel, MatColorDefinitionModel } from '@trackback/widgets';
import { Subject, Subscription, of } from 'rxjs';
import { finalize, retryWhen, switchMap, tap } from 'rxjs/operators';
import { BaseWidgetComponent } from '../base-widget.component';

/**
 * @ignore
 */
@Register('Button')
@Component({
  selector: 'tb-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ButtonComponent extends BaseWidgetComponent<ButtonInput, ButtonOutput> implements OnInit, OnDestroy {

  constructor(
    public injector?: Injector
  ) {
    super(injector);
  }
  private _subscriptions: Subscription[] = [];
  private _clicks$ = new Subject<void>();
  color: ThemePalette | null;
  @ViewChild('menuTrigger') MenuTrigger;

  protected setColorActive(type: 'foreground' | 'background', color: MatColorDefinitionModel, flag: boolean) {
    this.color = typeof color === 'object' ? color.palette : color;
    this._cd.markForCheck();
  }

  onClick(event: MouseEvent) {
    if (this.input.clickAction) {
      event.stopPropagation();
      this._clicks$.next();
    }
  }

  async ngOnInit() {
    await super.ngOnInit();
    this._subscriptions.push(this._clicks$.pipe(
      switchMap(() =>
        of(null).pipe(
          tap(() => this.updateOutput({ actionPending: true }, false)),
          switchMap(() =>
            this.dispatchActions(this.input.clickAction).pipe(
              finalize(() => this.updateOutput({ actionPending: false }, false)),
            )),
          retryWhen(() => this._clicks$)
        )),
    ).subscribe(() => {
    }));
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => {
      if (!subscription.closed) {
        subscription.unsubscribe();
      }
    });
    super.ngOnDestroy();
  }

  handleOpenMenuAction(action: LocalActionModel) {
    const payload: boolean = action.payload as boolean;
    if (payload && this.input.menuWidgets) {
      this.MenuTrigger.openMenu();
    }
    return of(null);
  }

  isLoading() {
    return this._clicks$;
  }
}

