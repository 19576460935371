import { ChangeDetectionStrategy, Component, HostBinding, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import { Register } from '@trackback/ng-common';
import { FormFieldOption, RadioGroupFieldInput, RadioGroupFieldOutput } from '@trackback/widgets';
import { combineLatest } from 'rxjs';
import { shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import {
  BaseFormFieldWidgetComponent
} from '../base-form-field-widget.component';

@Register('RadioGroupField')
@Component({
  selector: 'tb-radio-group-field',
  templateUrl: './radio-group-field.component.html',
  styleUrls: ['./radio-group-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class RadioGroupFieldComponent extends BaseFormFieldWidgetComponent<RadioGroupFieldInput, RadioGroupFieldOutput> implements OnInit {

  @HostBinding('style.paddingTop')
  paddingTop?: string;

  @HostBinding('style.paddingRight')
  paddingRight?: string;

  @HostBinding('style.paddingBottom')
  paddingBottom?: string;

  @HostBinding('style.paddingLeft')
  paddingLeft?: string;

  public readonly parsedOptions$ = combineLatest([this.input$, this.context$]).pipe(
    switchMap(([input, context]) => this._parser.parse(input && input.options, {
      context,
      log: !this._config || !this._config.PRODUCTION ? console.log : undefined
    })),
    shareReplay({
      bufferSize: 1,
      refCount: true
    })
  );

  removePreviousValue() {
    if (this.input.multiple) {
      this.updateOutput({ value: [] });
    }
  }

  async ngOnInit() {
    await super.ngOnInit();
    this.parsedOptions$.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(parsedOptions => {
      const options = parsedOptions as FormFieldOption[];
      return this.updateOutput({ options: options });
    });
  }

}