/**
 * A tree table provides an outlet for displaying tabular data in a tree structure.
 *
 * It uses widget templates for columns and inserts the active row into the widget's context.
 *
 * The header widgets do not get any context injected into them.
 *
 * The data for the table can come from any source that can be expressed as a binding,
 * the only requirement is that the output has to be an array.
 *
 * This is based on the [Angular Material Implementation](https://material.angular.io/components/tree/overview).
 *
 * <i>Hint:</i> It can be helpful to start with providing a literal array as data during prototyping and then replace it with
 * a binding once the table itself is functionally stable.
 *
 * @module widgets/tree-table
 * @example A simple table displaying information about books
 * <pre>{
    id: 'example-table',
    type: 'TreeTable',
    columns: [
    ],
    data: [
      {
        id: 1,
        title: 'Book of Daddyhood',
        author: 'Paul'
      },
      {
        id: 2,
        title: 'Book of Queries',
        author: 'Mike'
      },
      {
        id: 3,
        title: 'Book of Vim',
        author: 'Ash'
      },
      {
        id: 4,
        title: 'Book of Midnight Oil',
        author: 'Becca'
      }
    ]
  }</pre>
 *
 * @see [[utils/bindings.contextValue]]
 */

/** Required comment to display module description, wont be included in the documentation */

import { CollectionViewer } from "@angular/cdk/collections";
import { FlatTreeControl } from "@angular/cdk/tree";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Injector,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from "@angular/material/tree";
import { Register } from "@trackback/ng-common";
import { Expression, TreeTableColumnDefinitionModel, TreeTableColumnGroupDefinitionModel, TreeTableInput, TreeTableOutput, WidgetInputModel, isExpression } from "@trackback/widgets";
import { BehaviorSubject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { BaseWidgetComponent } from "../base-widget.component";
/**
 * @ignore
 */
@Register("TreeTable")
@Component({
  selector: "tb-tree-table",
  templateUrl: "./tree-table.component.html",
  styleUrls: ["./tree-table.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TreeTableComponent
  extends BaseWidgetComponent<TreeTableInput, TreeTableOutput>
  implements OnInit, AfterViewInit, CollectionViewer
{
  @HostBinding("style.max-height") tableMaxHeight = "auto";
  @HostBinding("style.gridTemplateColumns") get gridTemplateColumns() {
    return `repeat(${this.activeColumn.length}, auto)`;
  }
  @HostBinding("style.paddingLeft") get stylePaddingLeft() {
    return this.paddingLeft;
  }
  @HostBinding("style.paddingTop") get stylePaddingTop() {
    return this.paddingTop;
  }
  @HostBinding("style.paddingRight") get stylePaddingRight() {
    return this.paddingRight;
  }
  @HostBinding("style.paddingBottom") get stylePaddingBottom() {
    return this.paddingBottom;
  }

  readonly viewChange = new BehaviorSubject<{ start: number; end: number }>({
    start: 0,
    end: Number.MAX_VALUE,
  });

  readonly nodes$ = new BehaviorSubject<FlatTreeNode[]>([]);

  treeControl = new FlatTreeControl<FlatTreeNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  dataSource = new MatTreeFlatDataSource<TreeNode, FlatTreeNode>(
    this.treeControl,
    this.treeFlattener
  );

  headerRows: Array<Array<HeadNode>> = [];
  activeColumn: ActiveColumn[] = [];
  selectedRows: Node[] = [];

  isExpression = isExpression;
  isRawString = (it) => typeof it === "string";
  trackByHeadNode = (headNode: HeadNode) => headNode.name;
  trackByHeadRow = (headRow: Array<HeadNode>) => headRow.length;
  isRowSelected = (node: Node) => this.selectedRows.includes(node);
  toggleSelection = (event: MouseEvent, node: Node) => {
    const idx = this.selectedRows.indexOf(node);
    if (idx !== -1) {
      if (event.ctrlKey) {
        this.selectedRows.splice(idx);
      } else {
        this.selectedRows = [];
      }
    } else {
      if (event.ctrlKey) {
        this.selectedRows.push(node);
      } else {
        this.selectedRows = [node];
      }
    }
  };

  constructor(injector: Injector) {
    super(injector);
  }

  async ngOnInit() {
    await this.parseId();
    if (!isExpression(this.input.data)) {
      this.register({ rows: this.input.data as any });
    } else {
      this.register();
    }
    this.parse(this.input.data).subscribe((data) => {
      this.dataSource.data = data as TreeNode[];
      this.updateOutput({ rows: data as TreeNode[] });
    });
    if (isExpression(this.input.columns)) {
      this.parse(this.input.columns).subscribe(
        (
          parsedColumns: Array<
            TreeTableColumnDefinitionModel | TreeTableColumnGroupDefinitionModel
          >
        ) => {
          this.activeColumn = [];
          this.headerRows = [];
          this._getActiveColumn(parsedColumns);
          this._getHeaderRow(parsedColumns);
          this._cd.markForCheck();
        },
        console.error
      );
    } else {
      this._getActiveColumn(
        this.input.columns as (
          | TreeTableColumnDefinitionModel
          | TreeTableColumnGroupDefinitionModel
        )[]
      );
      this._getHeaderRow(
        this.input.columns as (
          | TreeTableColumnDefinitionModel
          | TreeTableColumnGroupDefinitionModel
        )[]
      );
    }
    if (this.input.firstColumnSticky) {
      this.addStyleClasses("firstColumnSticky");
    }
    this.init();
    this.dataSource
      .connect(this)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => this.nodes$.next(data));
  }

  ngAfterViewInit() {
    this.parse(this.input.height).subscribe((height) => {
      if (height) {
        this.tableMaxHeight = `${height}px`;
      }
    });
  }

  private _transformer(node: TreeNode, level: number) {
    const { children, ...nodeValues } = node;
    return {
      expandable: !!node.children && node.children.length > 0,
      level: level,
      ...nodeValues,
    };
  }

  private _getActiveColumn(
    columns: Array<
      TreeTableColumnDefinitionModel | TreeTableColumnGroupDefinitionModel
    >
  ) {
    columns.forEach((column) => {
      if (isTreeTableColumnDefinitionModel(column)) {
        const activeColumn = {
          width: column.width,
          widget: column.dataCell,
          shade: column.shade,
        } as ActiveColumn;
        this.activeColumn.push(activeColumn);
      } else {
        this._getActiveColumn(column.children);
      }
    });
  }

  private _getHeaderRow(
    columns: Array<
      TreeTableColumnDefinitionModel | TreeTableColumnGroupDefinitionModel
    >
  ) {
    let maxDepth = 1;
    columns.forEach((column) => {
      const childMaxDepth = this._getMaxDepth(column);
      if (childMaxDepth > maxDepth) {
        maxDepth = childMaxDepth;
      }
    });

    this._headerBuilder(columns, maxDepth);
  }

  private _headerBuilder(
    columns: Array<
      TreeTableColumnDefinitionModel | TreeTableColumnGroupDefinitionModel
    >,
    maxDepth: number,
    level: number = 0,
    childOfLastColumnInHeader = false,
    rootCol = 0
  ) {
    if (!(level in this.headerRows)) {
      this.headerRows[level] = [];
    }
    if (!(maxDepth - 1 in this.headerRows)) {
      this.headerRows[maxDepth - 1] = [];
    }
    columns.forEach((column, idx) => {
      const isRightmostCellInHeader =
        idx === columns.length - 1 &&
        (level === 0 || childOfLastColumnInHeader);
      const childCount = this._getColumnCount(column);
      const childMaxDepth = this._getMaxDepth(column);
      const header = {
        name: column.name,
        rowspan: 0,
        colspan: 0,
        widget: column.headerCell,
        shade: ('shade' in column) ? column.shade : false,
        rightmostCellInHeader: isRightmostCellInHeader,
        leftmostCellInHeader: rootCol === 0 && idx === 0,
        firstColumnInHeader: rootCol === 0 && (level > 0 || idx === 0),
        leftmostOfSecondColumn:
          (rootCol === 1 && idx === 0) || (level === 0 && idx === 1),
      } as HeadNode;
      if (childMaxDepth === maxDepth) {
        header.rowspan = 1;
        header.colspan = childCount;
        this.headerRows[level].push(header);
      }
      if (childMaxDepth < maxDepth) {
        header.colspan = childCount;
        if (level === 0) {
          header.rowspan = maxDepth - childMaxDepth + 1;
          this.headerRows[level].push(header);
        } else {
          header.rowspan = 1;
          if (isTreeTableColumnDefinitionModel(column)) {
            this.headerRows[maxDepth - 1].push(header);
          } else {
            this.headerRows[level].push(header);
          }
        }
      }

      if (!isTreeTableColumnDefinitionModel(column)) {
        this._headerBuilder(
          column.children,
          maxDepth,
          level + 1,
          isRightmostCellInHeader,
          rootCol || idx
        );
      }
    });
  }

  private _getMaxDepth(
    column: TreeTableColumnDefinitionModel | TreeTableColumnGroupDefinitionModel
  ): number {
    let maxDepth = 1;
    if (!isTreeTableColumnDefinitionModel(column)) {
      column.children.forEach((childColumn) => {
        const childMaxDepth = this._getMaxDepth(childColumn);
        maxDepth = childMaxDepth + 1;
      });
    }
    return maxDepth;
  }

  private _getColumnCount(
    column: TreeTableColumnDefinitionModel | TreeTableColumnGroupDefinitionModel
  ): number {
    let columnCount = 0;
    if (isTreeTableColumnDefinitionModel(column)) {
      columnCount = 1;
    } else {
      column.children.forEach((childColumn) => {
        const childOuput = this._getColumnCount(childColumn);
        columnCount = columnCount + childOuput;
      });
    }
    return columnCount;
  }
}

export function isTreeTableColumnDefinitionModel(
  input: any
): input is TreeTableColumnDefinitionModel {
  return (
    typeof input.name === "string" &&
    (typeof input.headerCell === "object" ||
      typeof input.headerCell === "string") &&
    typeof input.dataCell === "object"
  );
}

interface HeadNode {
  name: string;
  rowspan: number;
  colspan: number;
  widget: WidgetInputModel | Expression<any, string> | string;
  shade: boolean;
  rightmostCellInHeader: boolean;
  leftmostCellInHeader: boolean;
  firstColumnInHeader: boolean;
  leftmostOfSecondColumn: boolean;
}

interface TreeNode {
  children?: TreeNode[];
  [key: string]: any;
}

interface FlatTreeNode {
  expandable: boolean;
  level: number;
  [key: string]: any;
}

interface ActiveColumn {
  width: number;
  widget: WidgetInputModel | Expression<any, string>;
  shade: boolean;
}
