import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import {
  ActionDispatcher,
  ActionDispatcherService,
} from "../models/action-dispatcher.model";
import { EMPTY, forkJoin, Observable, of, throwError } from "rxjs";
import {
  APP_CONFIG,
  AppConfigModel,
  AppError,
  getType,
} from "@trackback/ng-common";
import { Inject, Injectable, Optional } from "@angular/core";
import { Store } from "@ngxs/store";
import { catchError, finalize, switchMap } from "rxjs/operators";
import { merge } from "lodash-es";
import { ensureArray } from "../utils/ensure-array";
import { ParserService } from "./parser.service";
import { WidgetsState } from "../state/widgets/widgets.state";
import safeForkJoin from "../utils/safe-fork-join";
import {
  WidgetActionModel,
  LocalBatchActionModel,
  LocalActionModel,
  ConditionalActionModel,
  RemoteActionModel,
  GlobalActionModel,
} from "@trackback/widgets";
import {
  RemoteWidgetActionError,
  RemoteWidgetActionResponseModel,
} from "@trackback/widgets/build/main/actions/remote";
import {
  isLocalAction,
  isRemoteAction,
  isGlobalAction,
  isLocalBatchAction,
  isConditionalAction,
  isRemoteError,
} from "../models/widget-action.model";

@Injectable()
export class DispatcherService implements ActionDispatcherService {
  constructor(
    private readonly http: HttpClient,
    private readonly store: Store,
    private readonly parser: ParserService,
    @Optional() @Inject(APP_CONFIG) private readonly config?: AppConfigModel
  ) {}

  dispatch: ActionDispatcher = (
    action?: WidgetActionModel | WidgetActionModel[],
    context?: Record<string, any>
  ) => {
    if (Array.isArray(action)) {
      action = action.filter(Boolean); // Remove falsy values
      if (action.length > 1) {
        return forkJoin(
          action.map((subAction) => this.dispatch(subAction, context))
        );
      } else if (action.length === 1) {
        action = action[0];
      } else {
        return EMPTY;
      }
    }

    if (!action) {
      return EMPTY;
    }

    // tslint:disable-next-line:no-unused-expression
    (!this.config || !this.config.PRODUCTION) &&
      console.log(`Action dispatched:`, action, context);

    // Get Dispatcher
    let dispatched: Observable<any>;

    if (isLocalAction(action)) {
      dispatched = this.dispatchLocalAction(action, context);
    } else if (isRemoteAction(action)) {
      dispatched = this.dispatchRemoteAction(action, context);
    } else if (isGlobalAction(action)) {
      dispatched = this.dispatchGlobalAction(action, context);
    } else if (isLocalBatchAction(action)) {
      dispatched = this.dispatchLocalBatchAction(action, context);
    } else if (isConditionalAction(action)) {
      dispatched = this.dispatchConditionalAction(action, context);
    } else {
      return throwError(
        new AppError(
          `widgets/unknown-action-type`,
          "application_error",
          `Cannot resolve action type of action = ${action}`
        )
      );
    }

    return this.handleDispatchResult(dispatched, action, context);
  };

  handleDispatchResult(
    dispatched: Observable<any>,
    sourceAction: WidgetActionModel,
    sourceContext?: Record<string, any>
  ): Observable<any> {
    return dispatched.pipe(
      switchMap((resultObject) => {
        // tslint:disable-next-line:no-unused-expression
        (!this.config || !this.config.PRODUCTION) &&
          console.log(`Action success:`, sourceAction);
        if (isGlobalAction(sourceAction)) {
          // Global actions never return a value by design, so we have to read it from the store
          // (where the global action should have saved it)
          resultObject = this.store.selectSnapshot(
            WidgetsState.getLastGlobalActionResult
          );
        }
        let actions: WidgetActionModel[];
        let subContext;
        if (
          resultObject &&
          typeof resultObject === "object" &&
          !Array.isArray(resultObject) &&
          resultObject.actions
        ) {
          subContext =
            (sourceAction.resultContextKey &&
              merge({}, sourceContext, {
                [sourceAction.resultContextKey]: (resultObject || {}).result,
              })) ||
            (resultObject || {}).result ||
            resultObject;
          actions = [
            ...ensureArray(resultObject.actions),
            ...ensureArray(sourceAction.onsuccess),
          ];
        } else {
          subContext =
            (sourceAction.resultContextKey &&
              merge({}, sourceContext, {
                [sourceAction.resultContextKey]: resultObject,
              })) ||
            resultObject;
          actions = ensureArray(sourceAction.onsuccess);
        }

        if (actions.length) {
          return this.dispatch(actions, subContext);
        } else {
          return of(subContext);
        }
      }),
      catchError((exception) => {
        // tslint:disable-next-line:no-unused-expression
        (!this.config || !this.config.PRODUCTION) &&
          console.log(`Action error:`, sourceAction, exception);
        // Collect error
        let error: AppError = new AppError(
          "widgets/action-failed",
          "application_error",
          `Error during action dispatch: ${exception.message}`
        );

        if (
          typeof exception === "object" &&
          typeof exception.error === "object" &&
          isRemoteError(exception.error.error)
        ) {
          const remoteError = exception.error.error as RemoteWidgetActionError;
          error = new AppError(
            remoteError.code,
            remoteError.messageTranslationKey,
            remoteError.developerMessage
          );
        } else if (
          typeof exception === "object" &&
          isRemoteError(exception.error)
        ) {
          error = new AppError(
            exception.error.code,
            exception.error.messageTranslationKey,
            exception.error.developerMessage
          );
        } else if (exception instanceof AppError) {
          error = exception;
        }

        // Collect actions
        let actions: WidgetActionModel[];
        const subContext: any =
          (sourceAction.resultContextKey &&
            merge({}, sourceContext, {
              [sourceAction.resultContextKey]: error,
            })) ||
          error;
        if (
          exception instanceof HttpErrorResponse &&
          typeof exception.error === "object" &&
          typeof exception.error.actions === "object"
        ) {
          actions = [
            ...ensureArray(exception.error.actions),
            ...ensureArray(sourceAction.onerror),
          ];
        } else {
          actions = ensureArray(sourceAction.onerror);
        }

        if (actions.length) {
          return this.dispatch(actions, subContext);
        } else if (sourceAction.oncomplete) {
          return EMPTY;
        } else {
          return throwError(subContext);
        }
      }),
      finalize(() => {
        // tslint:disable-next-line:no-unused-expression
        (!this.config || !this.config.PRODUCTION) &&
          console.log(`Action complete:`, sourceAction);
        if (sourceAction.oncomplete) {
          return this.dispatch(
            sourceAction.oncomplete,
            sourceContext
          ).toPromise();
        }
      })
    );
  }

  dispatchLocalBatchAction(
    action: LocalBatchActionModel,
    context?: Record<string, any>
  ): Observable<any> {
    return this.parser
      .parseOnce(action.widgetIds, {
        context: context,
        log: !this.config || !this.config.PRODUCTION ? console.log : undefined,
      })
      .pipe(
        switchMap((resolvedWidgetIds) => {
          const widgetIds = resolvedWidgetIds as string[];
          return safeForkJoin(
            widgetIds.map((widgetId) =>
              this.dispatch(
                {
                  sourceWidgetId: widgetId,
                  type: "local",
                  name: action.templateAction.name,
                  resultContextKey: action.templateAction.resultContextKey,
                  widgetId,
                  payload: action.templateAction.payload,
                  onsuccess: action.templateAction.onsuccess,
                  onerror: action.templateAction.onerror,
                  oncomplete: action.templateAction.oncomplete,
                  maxParseDepth: action.templateAction.maxParseDepth,
                  exclusiveParseProperties:
                    action.templateAction.exclusiveParseProperties,
                } as LocalActionModel,
                merge({}, context, {
                  [action.widgetIdContextKey || "localBatchId"]: widgetId,
                })
              )
            )
          );
        })
      );
  }

  dispatchConditionalAction(
    action: ConditionalActionModel,
    context?: Record<string, any>
  ): Observable<any> {
    return this.parser
      .parseOnce(action.boolExpr, {
        context: context,
        log: !this.config || !this.config.PRODUCTION ? console.log : undefined,
      })
      .pipe(
        switchMap((expressionResult) => {
          if (expressionResult) {
            return this.dispatch(action.ifAction, context);
          } else if (action.elseAction) {
            return this.dispatch(action.elseAction, context);
          } else {
            return EMPTY;
          }
        })
      );
  }

  dispatchRemoteAction(
    action: RemoteActionModel,
    context?: Record<string, any>
  ): Observable<any> {
    return this.parser
      .parseOnce([action.url, action.payload] as const, {
        context: context,
        maxParseDepth: action.maxParseDepth,
        keyWhitelist: action.exclusiveParseProperties,
        log: !this.config || !this.config.PRODUCTION ? console.log : undefined,
      })
      .pipe(
        switchMap(([resolvedUrl, resolvedPayload]) => {
          switch (action.method || "post") {
            case "post":
              return this.http.post<RemoteWidgetActionResponseModel>(
                resolvedUrl,
                resolvedPayload
              );
            case "delete":
              return this.http.delete<RemoteWidgetActionResponseModel>(
                resolvedUrl
              );
            case "patch":
              return this.http.patch<RemoteWidgetActionResponseModel>(
                resolvedUrl,
                resolvedPayload
              );
            case "put":
              return this.http.put<RemoteWidgetActionResponseModel>(
                resolvedUrl,
                resolvedPayload
              );
            case "get":
              return this.http.get<RemoteWidgetActionResponseModel>(
                resolvedUrl
              );
            case "uploadFile":
              const formData: FormData = new FormData();
              for (const [payloadKey, payloadValue] of Object.entries(
                resolvedPayload
              )) {
                if (
                  Array.isArray(payloadValue) &&
                  typeof payloadValue[0].name == "string" &&
                  typeof payloadValue[0].type == "string"
                ) {
                  payloadValue.map((file) => formData.append(payloadKey, file));
                } else {
                  formData.append(
                    payloadKey,
                    resolvedPayload[payloadKey] as any
                  );
                }
              }
              const headers = new HttpHeaders().append(
                "Accept",
                "application/json"
              );
              return this.http.post<RemoteWidgetActionResponseModel>(
                resolvedUrl,
                formData,
                { headers: headers }
              );
            default:
              return throwError(
                new AppError(
                  "widgets/invalid-http-method",
                  "application_error",
                  `Dispatcher service cannot handle remote actions with method ${action.method}`
                )
              );
          }
        })
      );
  }

  dispatchLocalAction(
    action: LocalActionModel,
    context?: Record<string, any>
  ): Observable<any> {
    return this.parser
      .parseOnce(action.widgetId, {
        context: context,
        log: !this.config || !this.config.PRODUCTION ? console.log : undefined,
      })
      .pipe(
        switchMap((parsedWidgetId) => {
          const widgetId = String(parsedWidgetId || action.sourceWidgetId);
          if (widgetId) {
            const localDispatcher = this.store.selectSnapshot(
              WidgetsState.getDispatcherFn
            )(widgetId);

            if (localDispatcher) {
              return this.parser
                .parseOnce(action.payload, {
                  context: context,
                  maxParseDepth: action.maxParseDepth,
                  keyWhitelist: action.exclusiveParseProperties,
                  log:
                    !this.config || !this.config.PRODUCTION
                      ? console.log
                      : undefined,
                })
                .pipe(
                  switchMap((resolvedPayload) =>
                    localDispatcher({ ...action, payload: resolvedPayload })
                  )
                );
            }

            return throwError(
              new AppError(
                `widgets/missing-dispatcher`,
                "application_error",
                `No dispatcher registered for widget id = ${widgetId}`
              )
            );
          }

          return throwError(
            new AppError(
              `widgets/no-local-action-target`,
              "application_error",
              `Local action (${action}) requires a target`
            )
          );
        })
      );
  }

  dispatchGlobalAction(
    action: GlobalActionModel,
    context?: Record<string, any>
  ): Observable<any> {
    if ("payload" in action) {
      return this.parser
        .parseOnce(action.payload, {
          context: context,
          maxParseDepth: action.maxParseDepth,
          keyWhitelist: action.exclusiveParseProperties,
          log:
            !this.config || !this.config.PRODUCTION ? console.log : undefined,
        })
        .pipe(
          switchMap((resolvedPayload) => {
            const globalAction = action as GlobalActionModel;
            const ActionType = getType(globalAction.name);
            const actionInstance = new ActionType(resolvedPayload);
            return this.store.dispatch(actionInstance);
          })
        );
    } else {
      const globalAction = action as GlobalActionModel;
      const ActionType = getType(globalAction.name);
      const actionInstance = new ActionType();
      return this.store.dispatch(actionInstance);
    }
  }
}
