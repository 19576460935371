<mat-form-field class="search-box">
  <mat-select
    [formControl]="_formControl"
    [placeholder]="input.label | parse: context | async"
    [disabled]="input.disabled"
    [required]="input.required"
    [multiple]="input.multiple"
    (blur)="onBlur($event)"
  >
    <mat-option>
      <ngx-mat-select-search
        [formControl]="filterCtrl"
        [placeholderLabel]="input.searchPlaceHolder | parse: context | async"
        [noEntriesFoundLabel]="
          input.noEntriesFoundLabel | parse: context | async
        "
      ></ngx-mat-select-search>
    </mat-option>
    <mat-option
      *ngFor="let option of filteredOptions$ | async"
      [value]="option[input.optionsValueKey || 'value']"
    >
      {{ option[input.optionsLabelKey || "label"] }}
    </mat-option>
  </mat-select>
  <mat-error *ngIf="firstFormControlError as error">
    {{ error.errorMessage | parse: context | async }}
  </mat-error>
</mat-form-field>
