<mat-sidenav-container (backdropClick)="hideActive()">
  <mat-sidenav
    class="navigation-container"
    [opened]="(_navOpen$ | async) || ((_navWidget$$ | async) && (isLarge$ | async))"
    [mode]="_sidenavMode$ | async" [fixedInViewport]="isHandheld$ | async"
    (keydown.escape)="setNavOpen(false)" disableClose>
    <ng-container *ngIf="_navWidget$$ | async as navWidget" [dynamicWidget]="navWidget"
                  [context]="_navTemplate$$ | async"></ng-container>
  </mat-sidenav>
  <mat-sidenav class="side-content-container" position="end"
               [style.marginTop.px]="(isHandheld$ | async) || (_sideContentMode$ | async) === 'over' ? 0 : (_headerHeight$ | async)"
               [fixedInViewport]="isHandheld$ | async"
               [opened]="_sideContentOpen$ | async"
               [mode]="_sideContentMode$ | async"
               (keydown.escape)="setSideContentOpen(false)"
               disableClose>
    <ng-container *ngIf="_sideContentWidget$$ | async as sideContentWidget" [dynamicWidget]="sideContentWidget"
                  [context]="_sideContentContext$$ | async"></ng-container>
  </mat-sidenav>
  <mat-sidenav-content>
    <ng-container *ngIf="_toolbarWidget$$ | async as toolbarWidget" [dynamicWidget]="toolbarWidget"
                  [context]="_toolbarTemplate$$ | async"></ng-container>
    <tb-tabs *ngIf="input?.tabsWidget as tabsWidget"
      [style.top.px]="_toolbarHeight$ | async"
      [input]="tabsWidget"></tb-tabs>
  </mat-sidenav-content>
</mat-sidenav-container>
