import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Register } from '@trackback/ng-common';
import { NavigationListInput, NavigationListOutput } from '@trackback/widgets';
import { BaseWidgetComponent } from '../base-widget.component';

@Register('NavigationList')
@Component({
  selector: 'tb-navigation-list',
  templateUrl: './navigation-list.component.html',
  styleUrls: ['./navigation-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class NavigationListComponent extends BaseWidgetComponent<NavigationListInput, NavigationListOutput> implements OnInit, OnDestroy {

  async ngOnInit() {
    // Skip Registration
    this.init();
  }

  ngOnDestroy(): void {
    // Skip De-registration
    this.destroy();
  }
}
