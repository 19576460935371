import { ChangeDetectionStrategy, Component, Injector, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Register } from '@trackback/ng-common';
import { LabelRowInput, LabelRowOutput } from '@trackback/widgets';
import { BaseWidgetComponent } from '../base-widget.component';

@Register('LabelRow')
@Component({
  selector: 'tb-label-row',
  templateUrl: './label-row.component.html',
  styleUrls: ['./label-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LabelRowComponent extends BaseWidgetComponent<LabelRowInput, LabelRowOutput> implements OnInit, OnDestroy {

  constructor(injector: Injector) {
    super(injector);
  }

  async ngOnInit() {
    // Skip Registration
    this.init();
  }

  ngOnDestroy(): void {
    // Skip De-registration
    this.destroy();
  }
}

