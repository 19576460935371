import {ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Register} from '@trackback/ng-common';
import { CheckBoxFieldInput, CheckBoxFieldOutput } from '@trackback/widgets';
import { BaseFormFieldWidgetComponent } from '../base-form-field-widget.component';


@Register('CheckBoxField')
@Component({
  selector: 'tb-check-box-field',
  templateUrl: './check-box-field.component.html',
  styleUrls: ['./check-box-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CheckBoxFieldComponent extends BaseFormFieldWidgetComponent<CheckBoxFieldInput, CheckBoxFieldOutput> implements OnInit {

}