import { DateAdapter } from '@angular/material/core';
import { Store } from '@ngxs/store';
import {
  DEFAULT_FALLBACK_LANGUAGE_CODE,
  TranslationState,
} from '@trackback/ng-lang';
import { distinctUntilChanged } from 'rxjs/operators';
import { dateFnsLocales } from '@trackback/ng-widgets';
import type { Locale } from 'date-fns';

export const appInitialiserFactory =
  (store: Store, dateAdapter: DateAdapter<Date, Locale>) => async () => {
    try {
      // Update uis date locale on each language change
      store
        .select(TranslationState.getLanguageCode)
        .pipe(distinctUntilChanged())
        .subscribe(newLanguageCode => {
          dateAdapter.setLocale(
            dateFnsLocales[newLanguageCode || DEFAULT_FALLBACK_LANGUAGE_CODE]
          );
        });
    } catch (error) {
      console.error('Error during application setup', error);
    }
  };
