import type { MapExpression } from "@trackback/widgets";
import {
  appendContext,
  combineLatestArray,
  combineLatestObject,
} from "../parser";
import type { NamedResolverFunction } from "../types";

const mapResolver: NamedResolverFunction<MapExpression<unknown, unknown>> = (
  parser,
  options,
  onData,
  [items, template, contextKey = "item"]
) =>
  parser.parseArray(
    [items, contextKey] as const,
    {
      error: onData.error,
      next: ([resolvedItems, resolvedContextKey]) => {
        if (!resolvedItems || typeof resolvedItems !== "object") {
          return onData.next(resolvedItems as any); // TODO: This can only happen through a bug or broken types
        }
        if (Array.isArray(resolvedItems)) {
          return combineLatestArray(
            resolvedItems.map(
              (item) => (onChange) =>
                parser.parseWithCallback(
                  template,
                  onChange,
                  appendContext(options, { [resolvedContextKey]: item })
                )
            ),
            onData
          );
        } else {
          const entries = Object.entries(resolvedItems);
          return combineLatestObject(
            entries.map(([key, value]) => ({
              fn: (onChange) =>
                parser.parseWithCallback(
                  template,
                  onChange,
                  appendContext(options, {
                    [resolvedContextKey]: {
                      key,
                      value,
                    },
                  })
                ),
              key,
            })),
            onData
          );
        }
      },
    },
    options
  );

export default mapResolver;
