import {Inject, Optional, Pipe, PipeTransform} from '@angular/core';
import {ParserService} from '../services/parser.service';
import {APP_CONFIG, AppConfigModel} from '@trackback/ng-common';
import { ParseOptions } from '../expressions/lib/expressions/parser';

@Pipe({
  name: 'parse',
  pure: true
})
export class ParsePipe implements PipeTransform {

  constructor(private readonly parser: ParserService, @Optional() @Inject(APP_CONFIG) private readonly config?: AppConfigModel) { }

  transform(value: any, context: Record<string, any>, options?: ParseOptions) {
    return this.parser.parse(value, {
      context,
      log: !this.config || !this.config.PRODUCTION ? console.log : undefined,
      ...options
    });
  }
}
