<mat-sidenav-container (backdropClick)="hideActive()" autosize>
  <mat-sidenav
    class="navigation-container"
    [opened]="(_navWidget$$ | async) ? opened : false"
    [mode]="_sidenavMode$ | async" [fixedInViewport]="isHandheld$ | async"
    (keydown.escape)="setNavOpen(false)" disableClose>
    <ng-container *ngIf="_navWidget$$ | async as navWidget" [dynamicWidget]="navWidget"
                  [context]="_navTemplate$$ | async"></ng-container>
  </mat-sidenav>
  <mat-sidenav class="side-content-container" position="end"
               [style.marginTop.px]="(isHandheld$ | async) || (_sideContentMode$ | async) === 'over' ? 0 : (_toolbarHeight$ | async)"
               [fixedInViewport]="isHandheld$ | async"
               [opened]="_sideContentOpen$ | async"
               [mode]="_sideContentMode$ | async"
               (keydown.escape)="setSideContentOpen(false)"
               disableClose>
    <ng-container *ngIf="_sideContentWidget$$ | async as sideContentWidget" [dynamicWidget]="sideContentWidget"
                  [context]="_sideContentContext$$ | async"></ng-container>
  </mat-sidenav>
  <mat-sidenav-content>
    <ng-container *ngIf="(_toolbarWidget$$ | async) as toolbarWidget" [dynamicWidget]="toolbarWidget"
                  [context]="_toolbarTemplate$$ | async"></ng-container>
    <div class="page-content-viewport"
         [style.marginTop.px]="_toolbarHeight$ | async" [style.paddingLeft]="paddingLeft" [style.paddingRight]="paddingRight"
         [style.paddingTop]="paddingTop" [style.paddingBottom]="paddingBottom">
      <div class="page-content-wrapper" [class.centered]="input?.mainContentCentered | parse:context | async">
        <ng-container *ngIf="(_mainContentWidget$$ | async) as mainContentWidget" [dynamicWidget]="mainContentWidget"
                      [context]="_mainContentTemplate$$ | async"></ng-container>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
