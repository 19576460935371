import type { FilteredExpression } from "@trackback/widgets";
import { filter, map, zip } from 'lodash-es';
import { appendContext, combineLatestArray } from "../parser";
import { createNamedExpressionResolver } from "../types";

export const filterResolver = createNamedExpressionResolver<
  FilteredExpression<unknown>
>(
  "filter",
  (parser, options, onData, [items, predicate, contextKey = "item"]) =>
    parser.parseArray(
      [items, contextKey] as const,
      {
        error: onData.error,
        next: ([resolvedItems, resolvedContextKey]) => {
          if (Array.isArray(resolvedItems)) {
            return combineLatestArray<unknown>(
              resolvedItems.map(
                (item) => (onChange) =>
                  parser.parseWithCallback(
                    predicate,
                    onChange,
                    appendContext(options, { [resolvedContextKey]: item })
                  )
              ),
              {
                error: onData.error,
                next: (resolvedPredicates: unknown[]) =>
                  onData.next(
                    map(filter(zip(resolvedItems, resolvedPredicates), 1), 0)
                  ),
              }
            );
          } else if (
            resolvedItems !== null &&
            typeof resolvedItems === "object"
          ) {
            const entries = Object.entries(resolvedItems);
            return combineLatestArray<unknown>(
              entries.map(
                ([key, value]) =>
                  (onChange) =>
                    parser.parseWithCallback(
                      predicate,
                      onChange,
                      appendContext(options, {
                        [resolvedContextKey]: { key, value },
                      })
                    )
              ),
              {
                error: onData.error,
                next: (resolvedPredicates) =>
                  onData.next(
                    map(filter(zip(entries, resolvedPredicates), 1), 0).reduce<
                      Record<string, unknown>
                    >(
                      (
                        result: Record<string, unknown>,
                        entry: [string, any] | undefined
                      ) => {
                        if (entry) {
                          result[entry[0]] = entry[1];
                        }
                        return result;
                      },
                      {}
                    )
                  ),
              }
            );
          } else {
            return onData.next(resolvedItems);
          }
        },
      },
      options
    )
);
