import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Register } from '@trackback/ng-common';
import { ImageInput, ImageOutput } from '@trackback/widgets';
import { BaseWidgetComponent } from '../base-widget.component';

@Register('Image')
@Component({
  selector: 'tb-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ImageComponent extends BaseWidgetComponent<ImageInput, ImageOutput> implements OnInit, OnDestroy {

  async ngOnInit() {
    // Skip Registration
    this.init();
  }

  ngOnDestroy(): void {
    // Skip Deregistration
    this.destroy();
  }
}
