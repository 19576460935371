/**
 * Progress spinners display loading state.
 *
 * This implementation follows the [Official Specification](https://material.io/design/components/progress-spinner.html) and
 * is based on the [Angular Material Implementation](https://material.angular.io/components/progress-spinner/examples).
 *
 * @module widgets/progress-spinner
 */

/** Required comment to display module description, wont be included in the documentation */

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { Register } from '@trackback/ng-common';
import { MatColorDefinitionModel, ProgressSpinnerInput, ProgressSpinnerOutput } from '@trackback/widgets';
import { BaseWidgetComponent } from '../base-widget.component';

/**
 * @ignore
 */
@Register('ProgressSpinner')
@Component({
  selector: 'tb-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ProgressSpinnerComponent extends BaseWidgetComponent<ProgressSpinnerInput, ProgressSpinnerOutput>
  implements OnInit, OnDestroy {

  color: ThemePalette | null;

  protected setColorActive(type: 'foreground' | 'background', color: MatColorDefinitionModel, flag: boolean) {
    this.color = typeof color === 'object' ? color.palette : color;
    this._cd.markForCheck();
  }

  async ngOnInit() {
    // Skip Registration
    this.init();
  }

  ngOnDestroy(): void {
    // Skip Deregistration
    this.destroy();
  }

}
