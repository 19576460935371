import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { Register } from '@trackback/ng-common';
import { BaseWidgetComponent, NgWidgetsModule } from '@trackback/ng-widgets';
import {
  DirectionalLayoutInput,
  ExpansionPanelTableInput,
  ExpansionPanelTableOutput,
} from '@trackback/widgets';
import { map } from 'rxjs/operators';

@Component({
  selector: 'tb-expansion-panel-table',
  templateUrl: './expansion-panel-table.component.html',
  styleUrls: ['./expansion-panel-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule, MatExpansionModule, NgWidgetsModule],
})
@Register('expansionpaneltable')
export class ExpansionPanelTableComponent extends BaseWidgetComponent<
  ExpansionPanelTableInput,
  ExpansionPanelTableOutput
> {
  public widgetContent$ = this.input$.pipe(
    map(
      input =>
        ({
          type: 'DirectionalLayout',
          orientation: 'vertical',
          widgets: input?.widgets ?? [],
        }) as DirectionalLayoutInput
    )
  );
}
