/**
 * A datatable provides an outlet for displaying tabular data in a controlled way.
 *
 * It uses widget templates for columns and inserts the active row into the widget's context.
 *
 * The header widgets do not get any context injected into them.
 *
 * The data for the table can come from any source that can be expressed as a binding,
 * the only requirement is that the output has to be an array.
 *
 * This implementation follows the [Official Specification](https://material.io/design/components/data-tables.html) and
 * is based on the [Angular Material Implementation](https://material.angular.io/components/table/overview).
 *
 * <i>Hint:</i> It can be helpful to start with providing a literal array as data during prototyping and then replace it with a
 * binding once the table itself is functionally stable.
 *
 * @module widgets/data-table
 * @example A simple table displaying information about books
 * <pre>{
    id: 'example-table',
    type: 'DataTable',
    columns: [
      {
        name: 'title',
        cells: [
          {
            type: 'header',
            widget: {
              id: 'title-header',
              type: 'Text',
              text: 'Title'
            }
          },
          {
            type: 'data',
            widget: {
              id: `row.${data('row.id')}.title`,
              type: 'Text',
              text: data('row.title')
            }
          }
        ]
      },
      {
        name: 'author',
        cells: [
          {
            type: 'header',
            widget: {
              id: 'title-header',
              type: 'TextComponent',
              text: 'Author'
            }
          },
          {
            type: 'data',
            widget: {
              id: `row.${data('row.id')}.author`,
              type: 'TextComponent',
              text: data('row.author')
            }
          }
        ]
      }
    ],
    mainHeaderRow: {
      type: 'header',
      activeColumns: ['title', 'author']
    },
    mainDataRow: {
      type: 'data',
      activeColumns: ['title', 'author']
    },
    data: [
      {
        id: 1,
        title: 'Book of Daddyhood',
        author: 'Paul'
      },
      {
        id: 2,
        title: 'Book of Queries',
        author: 'Mike'
      },
      {
        id: 3,
        title: 'Book of Vim',
        author: 'Ash'
      },
      {
        id: 4,
        title: 'Book of Midnight Oil',
        author: 'Becca'
      }
    ]
  }</pre>
  DOWNLOADING XLS
  {
  format: 'XLSX',
  fileName: 'testfile',
  excelConfig: {
    header: ['Sno', 'Area'], // modified name of the column.
    data: ['sno', 'Areaname']// actual column name
  }
}
 *
 * @see [[utils/bindings.contextValue]]
 */

/** Required comment to display module description, wont be included in the documentation */

import { ChangeDetectionStrategy, Component, Injector, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyTable as MatTable, MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Register } from '@trackback/ng-common';
import { DataTableInput, DataTableOutput, GlobalActionModel, isExpression } from '@trackback/widgets';
import { isPrimitiveType } from '../../utils/is-primitive-type';
import { BaseWidgetComponent } from '../base-widget.component';

/**
 * @ignore
 */
@Register('DataTable')
@Component({
  selector: 'tb-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class DataTableComponent extends BaseWidgetComponent<DataTableInput, DataTableOutput> implements OnInit {

  public dataSource: MatTableDataSource<any>;

  constructor(injector: Injector) {
    super(injector);
  }

  @ViewChild('table') private table: MatTable<any[]>;

  async ngOnInit() {
    await super.ngOnInit();
    await this.parseId();
    if (!isExpression(this.input.data)) {
      this.register({ rows: this.input.data });
    } else {
      this.register();
    }
    this.parse(this.input.data)
      .subscribe(data => {
        const dataSource = data as Record<string, any>[];
        this.dataSource = new MatTableDataSource(dataSource);
      });
    this.init();
  }

  handleDownloadAction(action) {
    const payload: any = action.payload;
    if (payload.format === 'XLSX') {
      const fileName = (payload.fileName) ? `${payload.fileName}.xlsx` : `datatable-${new Date().getTime()}.xlsx`;
      let excelData: any[];
      if (payload.excelConfig) {
        // removing unwanted objects
        excelData = this.dataSource.filteredData.map(object => {
          // Trim down each object in the data source to only have the keys we want with the names we want.
          const trimmedDownObject: object = {};
          payload.excelConfig.data.forEach((key, index) => {
            const keyParts: string[] = key.split('.')
              , header: string = payload.excelConfig.header[index]
            ;
            if (keyParts.length > 1) {
              trimmedDownObject[header] = keyParts.reduce((reduceObject, path) => {
                return (reduceObject || {})[path];
              }, object);
            } else {
              trimmedDownObject[header] = object[key];
            }
          });
          return trimmedDownObject;
        });
      } else {
        excelData = this.dataSource.filteredData;
      }

      return this.dispatchActions({
        type: 'global',
        name: 'SaveAsXLSX',
        payload: {
          excelData: excelData,
          fileName: fileName
        }
      } as GlobalActionModel);
    } else {
      return super.handleDownloadAction(action);
    }
  }

  onRowClick(event: MouseEvent, row) {
    if (this.input.rowClickAction) {
      event.stopPropagation();
      this.dispatchActionsPromise(this.input.rowClickAction, { row });
    }
  }

  isPrimitive = (value: unknown) => isPrimitiveType(typeof value);
  isExpression = isExpression;
}
