import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { Register } from "@trackback/ng-common";
import {
  ExpansionPanelInput,
  ExpansionPanelOutput
} from "@trackback/widgets";
import {
  BaseWidgetComponent
} from "../base-widget.component";

@Component({
  selector: "tb-expansion-panel",
  templateUrl: "./expansion-panel.component.html",
  styleUrls: ["./expansion-panel.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
@Register("ExpansionPanel")
export class ExpansionPanelComponent
  extends BaseWidgetComponent<ExpansionPanelInput, ExpansionPanelOutput>
  implements OnInit, OnDestroy
{
  async ngOnInit() {
    // Skip Registration
    this.init();
  }

  ngOnDestroy(): void {
    // Skip Deregistration
    this.destroy();
  }
}
