import { ChangeDetectionStrategy, Component, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import { Register } from '@trackback/ng-common';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BasePageWidgetComponent } from '../base-page-widget.component';
import { DefaultPageInput, DefaultPageOutput, WidgetInputModel, LocalActionModel } from '@trackback/widgets';

@Register('DefaultPage')
@Component({
  selector: 'tb-default-page',
  templateUrl: './default-page.component.html',
  styleUrls: ['./default-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class DefaultPageComponent extends BasePageWidgetComponent<DefaultPageInput, DefaultPageOutput> implements OnInit {

  public readonly _mainContentWidget$$ = new BehaviorSubject<WidgetInputModel | undefined>(undefined);
  public readonly _mainContentTemplate$$ = new BehaviorSubject<Record<string, any> | undefined>(undefined);
  public opened: boolean;
  constructor(injector: Injector) {
    super(injector);
  }

  async ngOnInit() {
    await super.ngOnInit();
    this._mainContentWidget$$.next(this.input.mainContentWidget);
    this.isLarge$.pipe(takeUntil(this.destroyed$))
      .subscribe((isLarge) => {
        if (isLarge) {
          this.opened = true;
          this.setNavOpen(true);
        }
      });
    this._navOpen$.pipe(takeUntil(this.destroyed$))
      .subscribe((navOpen) => {
        this.opened = navOpen;
      });
  }

  handleSetMainContentWidgetAction(action: LocalActionModel): Observable<any> {
    this._mainContentWidget$$.next(action.payload as WidgetInputModel);
    return of(action.payload);
  }

  handleSetMainContentTemplateAction(action: LocalActionModel): Observable<any> {
    this._mainContentTemplate$$.next(action.payload as Record<string, any>);
    return of(action.payload);
  }
}
