<mat-form-field
  [floatLabel]="(input.label | parse: context | async) ? 'auto' : 'never'"
>
  <mat-label *ngIf="input.label | parse: context | async as label">{{
    label
  }}</mat-label>
  <input
    [type]="hide ? (input.fieldType | parse: context | async) : 'text'"
    matInput
    [formControl]="_formControl"
    [placeholder]="input.placeholder | parse: context | async"
    [required]="input.required"
    [readonly]="input.readonly"
    (blur)="onBlur($event)"
    (keydown.enter)="onEnter($event)"
  />
  <button
    *ngIf="input.fieldType === 'password'"
    mat-icon-button
    matSuffix
    (click)="hide = !hide"
    [attr.aria-label]="'Hide password'"
    [attr.aria-pressed]="hide"
  >
    <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
  </button>
  <mat-error *ngIf="firstFormControlError as error">
    {{ error.errorMessage | parse: context | async }}
  </mat-error>
</mat-form-field>
