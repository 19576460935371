<div [style.paddingLeft]="paddingLeft" [style.paddingRight]="paddingRight"
     [style.paddingTop]="paddingTop" [style.paddingBottom]="paddingBottom" >
  <mat-checkbox  [aria-label]="input.placeholder | parse:context | async"
                 [formControl]="_formControl"
                 [labelPosition]="input.labelPosition"
                 [required]="input.required"
                 [indeterminate] = "input.indeterminate | parse:context | async"
  >
    <mat-label *ngIf="(input.label | parse:context | async) as label">{{label}}</mat-label>
  </mat-checkbox>
  <mat-error *ngIf="firstFormControlError as error">
    {{error.errorMessage | parse:context | async}}
  </mat-error>
</div>

