// tslint:disable: jsdoc-format
/**
 * {
    id: 'any',
    type: 'SearchBoxField',
    label: translate(registerTranslationKey('Search for Dealer')),
    defaultValue: 'null',
    selectall:true,
    restrictedSearch: true,
    restrictedSearchDefault: [{
      value: 'all',
      label: 'All'
    }]
  }
 */
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { Register } from "@trackback/ng-common";
import { uniqBy } from "lodash-es";
import { combineLatest } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  map,
  pairwise,
  startWith,
  switchMap,
} from "rxjs/operators";
import {
  BaseFormFieldWidgetComponent
} from "../base-form-field-widget.component";
import { FormFieldOption, FormFieldOutputModel, SearchBoxFieldInput } from "@trackback/widgets";

const SYMBOL_PREVIOUS_VALUE = Symbol("PREVIOUS_VALUE");

/**
 * @title Option groups autocomplete
 */
@Component({
  selector: "tb-search-box-field",
  templateUrl: "./search-box-field.component.html",
  styleUrls: ["./search-box-field.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush, // Widget should be optimised for performance
  encapsulation: ViewEncapsulation.None, // Disabling view encapsulation allows for easier styling
})
@Register("SearchBoxField")
export class SearchBoxFieldComponent
  extends BaseFormFieldWidgetComponent<
    SearchBoxFieldInput,
    FormFieldOutputModel
  >
  implements OnInit
{
  /** control for the MatSelect filter keyword
   * this is used to handel the search box localy which should not be updated to global state
   */
  public filterCtrl: UntypedFormControl = new UntypedFormControl();
  public _formControl: UntypedFormControl = new UntypedFormControl();

  public filteredOptions$ = combineLatest([this.input$, this.context$]).pipe(
    switchMap(([input, context]) =>
      this._parser
        .parse(input, { context })
        .pipe(map((parsedInput) => [parsedInput, context] as const))
    ),
    switchMap(([parsedInput, context]) =>
      this._parser
        .parse([parsedInput.options, parsedInput.restrictedSearchDefault], {
          context,
        })
        .pipe(
          map(
            ([parsedOptions, parsedRestrictedSearchDefault]) =>
              [
                parsedInput,
                parsedOptions,
                parsedRestrictedSearchDefault,
              ] as const
          )
        )
    ),
    switchMap(
      ([
        { optionsLabelKey, optionsValueKey, defaultValue, restrictedSearch },
        parsedOptions,
        parsedRestrictedSearchDefault,
      ]) => {
        const labelKey = optionsLabelKey || "label";
        const valueKey = optionsValueKey || "value";
        const defaultOptions: Record<string, unknown>[] = [];
        if (restrictedSearch && parsedRestrictedSearchDefault) {
          defaultOptions.push(...parsedRestrictedSearchDefault);
        }
        if (typeof defaultValue !== "undefined") {
          const defaultOption = parsedOptions.find(
            (e) => e[valueKey] === Number(defaultValue)
          );
          if (defaultOption) {
            defaultOptions.push(defaultOption);
          }
        }
        return this.filterCtrl.valueChanges.pipe(
          debounceTime(400),
          startWith(""),
          distinctUntilChanged(),
          map((searchValue) => {
            let result: Record<string, unknown>[];
            if (!searchValue) {
              // setting value when search is empty
              if (restrictedSearch) {
                // return the value of old search
                return SYMBOL_PREVIOUS_VALUE;
              } else {
                result = parsedOptions;
              }
            } else {
              searchValue = searchValue.toLowerCase();
              result = parsedOptions.filter(
                (option) =>
                  (option[labelKey] as string)
                    .toLowerCase()
                    .indexOf(searchValue) > -1
              );
              const value = this._formControl.value;
              // to avoid unselecting select box when no option is selcted.
              if (value && restrictedSearch) {
                const formValueOption = parsedOptions.find(
                  (option) => option[valueKey] === value
                );

                if (result.length) {
                  result = [...result, formValueOption];
                } else {
                  result = [formValueOption];
                }
              }
            }
            return uniqBy(
              defaultOptions.concat(result),
              (opt) => opt[valueKey]
            );
          }),
          startWith(defaultOptions),
          distinctUntilChanged(),
          pairwise(),
          map(([previous, current]) =>
            current === SYMBOL_PREVIOUS_VALUE
              ? (previous as FormFieldOption[])
              : current
          )
        );
      }
    )
  );
}