import { Store } from '@ngxs/store';
import { APP_CONFIG, Register } from '@trackback/ng-common';
import { DataStoreInputModel, LocalActionModel, WidgetInputModel } from '@trackback/widgets';
import { clone, isEqual, isUndefined, uniq, uniqBy } from 'lodash-es';
import { BehaviorSubject, Observable, Subject, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, map, skip, switchMap, take, takeUntil } from 'rxjs/operators';
import { ACTION_DISPATCHER } from '../models/action-dispatcher.model';
import { ParserService } from '../services/parser.service';
import { DeregisterWidget, RegisterWidget } from '../state/widgets/widgets.actions';
import { STRUCTURAL_WIDGET_TYPE_GROUP } from './index';
import { WidgetResolver } from './widget-resolver';
import { WidgetDefinitionTuple } from '../models/widget-input.model';

@Register('dataStore', STRUCTURAL_WIDGET_TYPE_GROUP)
export class DataStoreWidget extends WidgetResolver<DataStoreInputModel<WidgetInputModel>> {

  private id = '';
  private inputContext$ = new Subject<Record<string, any>>();
  private _data$ = new BehaviorSubject<Array<any>>([]);

  private readonly _store = this._injector.get(Store);
  private readonly _parser = this._injector.get(ParserService);
  private readonly _dispatcher = this._injector.get(ACTION_DISPATCHER);
  private readonly _config = this._injector.get(APP_CONFIG, null);
  private readonly _destroy$ = new Subject();

  readonly handleAction = (action: LocalActionModel): Observable<any> => {
    const payload = action.payload as DataStoreActionPayload;
    const data = clone(this._data$.getValue() as Array<any>);
    switch (action.name) {
      case 'Push':
        if (payload.data) {
          const newData = data.concat(payload.data);
          this.dataValidatorAndSetter(newData);
        } else {
          console.error(`Data is not passed in payload`);
        }
        break;
      case 'Unshift':
        if (payload.data) {
          data.unshift(payload.data);
          this.dataValidatorAndSetter(data);
        } else {
          console.error(`Data is not passed in payload`);
        }
        break;
      case 'Pop':
        data.pop();
        this.dataValidatorAndSetter(data);
        break;
      case 'Shift':
        data.shift();
        this.dataValidatorAndSetter(data);
        break;
      case 'Delete':
        if (!isUndefined(payload.key) && !isUndefined(payload.value)) {
          const clearedData = data.filter(e => e[payload.key] !== payload.value);
          this.dataValidatorAndSetter(clearedData);
        } else if (!isUndefined(payload.index)) {
          data.splice(payload.index, 1);
          this.dataValidatorAndSetter(data);
        } else {
          console.error(`Valid key and value or index required in payload`);
        }
        break;
      case 'SetData':
        this.dataValidatorAndSetter(payload.data);
        break;
      default:
        console.error(`Unknown action (${action.name}) on structural data widget`);
    }
    return of(null);
  }

  dataValidatorAndSetter(data: Array<any>) {
    let newData = data;
    if (this._input.uniqueDataSet) {
      if (this._input.uniqueDataProperty) {
        newData = uniqBy(data, this._input.uniqueDataProperty);
      } else {
        newData = uniq(data);
      }
    }
    this._data$.next(newData);
  }

  connect() {
    // Register widget to enable local actions to be dispatched against it
    this.inputContext$.pipe(
      takeUntil(this._destroy$),
      distinctUntilChanged(isEqual),
      switchMap(context => {
        return this._parser.parse(this._input.id, {
          context,
          log: !this._config || !this._config.PRODUCTION ? console.log : undefined,
        });
      })
    ).subscribe(widgetId => {
      if (widgetId) {
        this.id = String(widgetId);
        this._store.dispatch(new RegisterWidget(this.id, this._input.alias, this.handleAction));
        this.inputContext$.pipe(
          take(1)
        ).subscribe(context => {
          if (this._input.afterRegisterAction) {
            this._dispatcher.dispatch({ ...this._input.afterRegisterAction, sourceWidgetId: this.id }, context).toPromise();
          }
        });

        combineLatest([
          this._data$.pipe(skip(1)),
          this.inputContext$
        ]).pipe(
          takeUntil(this._destroy$),
          distinctUntilChanged(isEqual)
        ).subscribe(([data, context]) => {
          const newContext = {
            ...context,
            [this._input.dataAlias || 'storedData']: data
          };
          if (this._input.onDataChanged) {
            this._dispatcher.dispatch({ ...this._input.onDataChanged, sourceWidgetId: this.id }, newContext).toPromise();
          }
        });
      }
    });

    this.inputContext$.pipe(
      take(1),
      switchMap(context => {
        return this._parser.parse(this._input.data, {
          context,
          log: !this._config || !this._config.PRODUCTION ? console.log : undefined,
        });
      })
    ).subscribe(data => {
      const newData = data as Array<any>;
      this.dataValidatorAndSetter(newData);
    });
  }

  disconnect() {
    if (this._input.id) {
      this._store.dispatch(new DeregisterWidget(this.id, this._input.resetOnDestroy));
    }
    this._destroy$.next();
    this._destroy$.complete();
  }

  getState(context?: Record<string, any>): Observable<WidgetDefinitionTuple[]> {
    this.inputContext$.next(context);
    return this._data$.pipe(
      takeUntil(this._destroy$),
      distinctUntilChanged(isEqual),
      map(data => {
        const newContext = {
          ...context,
          [this._input.dataAlias || 'storedData']: data
        };
        return [[this._input.widget, newContext] as WidgetDefinitionTuple];
      })
    );
  }
}

export interface DataStoreActionPayload<T = any> {
  readonly data?: Array<T>;
  readonly index?: number;
  readonly key?: string;
  readonly value?: any;
}