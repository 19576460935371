import type { FormatDateExpression } from '@trackback/widgets';
import { createSimpleNamedObservableExpressionResolver } from '../types';
import { Observable } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { parseISO, format } from 'date-fns';
import locales from '../utils/date-fns-locales';

// See https://github.com/date-fns/date-fns/blob/main/docs/unicodeTokens.md for problem with mismatching tokens in MomentJS
export const createFormatDateResolver = (currentLocale$: Observable<string>) =>
  createSimpleNamedObservableExpressionResolver<FormatDateExpression>(
    'formatDate',
    (utcDate, formatString) =>
      currentLocale$.pipe(
        distinctUntilChanged(),
        map(locale =>
          format(
            typeof utcDate === 'string' ? parseISO(utcDate) : new Date(utcDate),
            formatString.replace(/D/g, 'd').replace(/Y/g, 'y'),
            {
              locale: locales[locale],
            }
          )
        )
      )
  );
