import { FormattedDateRelativeToExpression } from '@trackback/widgets';
import { formatDistance, formatDistanceToNow, parseISO } from 'date-fns';
import { Observable } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { createSimpleNamedObservableExpressionResolver } from '../types';
import locales from '../utils/date-fns-locales';

export const createFormatDateRelativeResolver = (
  currentLocale$: Observable<string>
) =>
  createSimpleNamedObservableExpressionResolver<FormattedDateRelativeToExpression>(
    'formatDateRelative',
    (utcDate, options) =>
      currentLocale$.pipe(
        distinctUntilChanged(),
        map(locale => {
          const firstDate =
            typeof utcDate === 'string' ? parseISO(utcDate) : new Date(utcDate);
          if (options?.otherUtcDate) {
            const otherDate =
              typeof options?.otherUtcDate === 'string'
                ? parseISO(options?.otherUtcDate)
                : new Date(options?.otherUtcDate);
            return formatDistance(firstDate, otherDate, {
              addSuffix: !options?.withoutSuffix,
              locale: locales[locale],
            });
          }
          return formatDistanceToNow(firstDate, {
            addSuffix: !options?.withoutSuffix,
            locale: locales[locale],
          });
        })
      )
  );
