<div [style.paddingLeft]="paddingLeft" [style.paddingRight]="paddingRight" [style.paddingTop]="paddingTop"
  [style.paddingBottom]="paddingBottom">

  <mat-slide-toggle [formControl]="_formControl" [disabled]="input.disabled | parse:context | async"
    [required]="input.required | parse:context | async" [labelPosition]="input.labelPosition"
    [aria-label]="input.placeholder | parse:context | async" [color]="input.color | parse:context | async">
    <mat-label *ngIf="(input.label | parse:context | async) as label">{{label}}</mat-label>
  </mat-slide-toggle>

  <mat-error *ngIf="firstFormControlError as error">
    {{error.errorMessage | parse:context | async}}
  </mat-error>
</div>