import type { SearchableExpression } from "@trackback/widgets";
import { createSimpleNamedExpressionResolver } from "../types";

export const SEARCHABLE_SEPARATOR = "◬";
function makeSearchable(input: unknown): string {
  if (Array.isArray(input)) {
    return input.map((item) => makeSearchable(item)).join(SEARCHABLE_SEPARATOR);
  } else if (typeof input === "object" && input !== null) {
    return Object.values(input)
      .map((value) => makeSearchable(value))
      .join(SEARCHABLE_SEPARATOR);
  } else if (typeof input === "string") {
    return input.toLowerCase();
  } else if (typeof input === "number") {
    return String(input);
  } else {
    return "";
  }
}

export const searchableResolver =
  createSimpleNamedExpressionResolver<SearchableExpression>(
    "searchable",
    makeSearchable
  );
