import {ChangeDetectionStrategy, Component, HostBinding, Injector, OnInit, ViewEncapsulation} from '@angular/core';
import {Register} from '@trackback/ng-common';
import { AccordionInput, AccordionOutput } from '@trackback/widgets';
import { BaseWidgetComponent } from '../../widgets/base-widget.component';

@Component({
  selector: 'tb-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
@Register('Accordion')
export class AccordionComponent extends BaseWidgetComponent<AccordionInput, AccordionOutput> implements OnInit {

  @HostBinding('style.paddingTop')
  paddingTop;

  @HostBinding('style.paddingRight')
  paddingRight;

  @HostBinding('style.paddingBottom')
  paddingBottom;

  @HostBinding('style.paddingLeft')
  paddingLeft;

  constructor(injector: Injector) {
    super(injector);
  }

  async ngOnInit() {
    await super.ngOnInit();
  }
}

